// React and external library imports
import React from "react";
// Application-specific imports
import { INoDataProps } from "../../common/interfaces/IForm";
import { TwButton } from "./tw/TwButton";
import { TwTitle } from "./tw/TwTitle";

/**
 * NoData component displays a message indicating no data is available, with an optional button.
 *
 * @component
 * @param {INoDataProps} props - The properties for the NoData component.
 * @param {string} props.title - The title or message to display when no data is available.
 * @param {string} [props.type="button"] - The type of the button. Defaults to "button".
 * @param {() => void} [props.onClick] - Optional callback function to handle button clicks.
 * @param {string} [props.buttonColor="blue"] - The color of the button. Defaults to "blue".
 * @param {string} [props.buttonTitle] - The text to display on the button.
 * @param {boolean} [props.buttonShow=true] - Whether to show the button. Defaults to true.
 * @returns {JSX.Element} The rendered NoData component.
 */
export const NoData: React.FC<INoDataProps> = ({
  title,
  type = "button",
  onClick,
  buttonColor = "blue",
  buttonTitle,
  buttonShow = true,
  buttonClass,
}) => (
  <div className="col-span-1 md:col-span-2">
    <div className="bg-white shadow-md rounded-lg px-7 py-5 items-center justify-center h-full">
      <div className="text-gray-400 flex flex-col items-center">
        <TwTitle title={title} className="mb-4" />
        {buttonShow && (
          <TwButton
            title={buttonTitle}
            color={buttonColor}
            className={`w-full md:w-auto ${buttonClass}`}
            onClick={onClick}
            type={type}
          />
        )}
      </div>
    </div>
  </div>
);

// React and external library imports
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Application-specific imports
import ShortUrlForm from "./ShortUrlForm";
import { IShortUrl } from "../../common/interfaces/IForm";
import { useShortUrlMutateQuery } from "../../queries/useShortUrlQuery";

/**
 * ShortUrlCreate component is responsible for rendering the form to create a new short URL.
 * It uses data from the `useShortUrlMutateQuery` hook and passes it to the `ShortUrlForm` component.
 *
 * @returns {JSX.Element} The rendered ShortUrlCreate component.
 */
const ShortUrlCreate: React.FC = () => {
  const { id }: any = useParams();
  const { t } = useTranslation();
  const {
    isCreating,
    createShortUrl,
    apiError,
    isApiError,
    isSuccess,
    isGeneralError,
    apiSuccessMessage,
  } = useShortUrlMutateQuery(id);
  /**
   * Handles form submission by calling the createShortUrl function from the hook.
   *
   * @param {IShortUrl} data - The data from the ShortUrlForm submission.
   */
  const onSubmit = (data: IShortUrl) => {
    createShortUrl(data);
  };

  return (
    <div className="max-w-5xl p-2 mx-auto my-5 bg-white rounded-md">
      <ShortUrlForm
        campaignKey={id}
        onSubmit={onSubmit}
        isApiError={isApiError}
        apiError={apiError}
        isSuccess={isSuccess}
        isPending={isCreating}
        isGeneralError={isGeneralError}
        apiSuccessMessage={apiSuccessMessage}
        formTitle={t("shortUrlCreate.formTitle")}
        submitButton={t("shortUrlCreate.button.create")}
      />
    </div>
  );
};

export default ShortUrlCreate;

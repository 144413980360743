// React and external library imports
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Application-specific imports
import { IShortUrl } from "../../common/interfaces/IForm";
import {
  useShortUrlFetchQuery,
  useShortUrlMutateQuery,
} from "../../queries/useShortUrlQuery";
import ShortUrlForm from "./ShortUrlForm";

/**
 * ShortUrlEdit component is responsible for rendering the form to edit an existing short URL.
 * It fetches the existing short URL data for editing and handles the update operation using
 * the `useShortUrlMutateQuery` hook.
 *
 * @returns {JSX.Element} The rendered ShortUrlEdit component.
 */
const ShortUrlEdit: React.FC = () => {
  const { t } = useTranslation();
  const { id, campaign_key: campaignKey }: any = useParams();

  // Fetch existing data for editing
  const { data: editData }: any = useShortUrlFetchQuery(campaignKey, {
    url_id: id,
  });

  // Mutate query for update operation
  const {
    apiError,
    isApiError,
    isUpdated,
    isUpdating,
    isGeneralError,
    apiSuccessMessage,
    updateShortUrl,
  } = useShortUrlMutateQuery(id);

  /**
   * Handles form submission by calling the updateShortUrl function from the hook.
   *
   * @param {IShortUrl} data - The data from the ShortUrlForm submission.
   */
  const onSubmit = (data: IShortUrl) => {
    updateShortUrl(id, data);
  };

  return (
    <div className="max-w-5xl p-2 mx-auto my-5 bg-white rounded-md shadow-sm">
      <ShortUrlForm
        editData={editData}
        campaignKey={campaignKey}
        onSubmit={onSubmit}
        isApiError={isApiError}
        apiError={apiError}
        isSuccess={isUpdated}
        isPending={isUpdating}
        isGeneralError={isGeneralError}
        apiSuccessMessage={apiSuccessMessage}
        formTitle={t("shortUrlEdit.formTitle")}
        submitButton={t("shortUrlEdit.button.update")}
      />
    </div>
  );
};

export default ShortUrlEdit;

// React and external library imports
import React from "react";
import { Dropdown } from "flowbite-react";
//Icons
import { SlOptionsVertical } from "react-icons/sl";
// Application-specific imports
import { IActionItemsProps } from "../../../common/interfaces/IForm";

/**
 * Renders a dropdown menu with action items.
 *
 * Uses Flowbite's Dropdown to display a menu with customizable actions. Each action can include a title, optional icon, and click handler.
 *
 * @param {IActionItemsProps} props - Component properties.
 * @param {Array<{ title: string; icon?: React.ReactNode; onclick?: () => void }>} props.actions - List of actions, each with a title, optional icon, and click handler.
 * @param {string} [props.className] - Optional CSS class for the dropdown trigger icon.
 *
 * @returns {JSX.Element} The rendered `TwActionItems` component.
 *
 * @example
 * <TwActionItems
 *   actions={[
 *     { title: 'Edit', icon: <EditIcon />, onclick: () => console.log('Edit clicked') },
 *     { title: 'Delete', icon: <DeleteIcon />, onclick: () => console.log('Delete clicked') }
 *   ]}
 *   className="my-custom-class"
 * />
 */
export const TwActionItems: React.FC<IActionItemsProps> = ({
  actions,
  className,
}) => (
  <Dropdown
    placement="bottom"
    label={
      <SlOptionsVertical
        className={`h-4 w-4 text-gray-700 custom-action-item ${className}`}
      />
    }
    arrowIcon={false}
    inline
  >
    {actions?.map((action) => (
      <Dropdown.Item
        onClick={action.onclick}
        icon={action.icon}
        key={action.title}
      >
        {action.title}
      </Dropdown.Item>
    ))}
  </Dropdown>
);

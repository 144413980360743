// React and external library imports
import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Application-specific imports
import { ICampaign } from "../../common/interfaces/IForm";
import {
  useCampaignFetchQuery,
  useCampaignMutateQuery,
} from "../../queries/useCampaignQuery";
import CampaignForm from "./CampaignForm";

/**
 * CampaignEdit component allows for editing an existing campaign.
 *
 * @component
 * @example
 * return <CampaignEdit />;
 *
 * This component fetches the current campaign data using the `useCampaignFetchQuery`
 * hook based on the campaign ID from the URL parameters. It then uses the `useCampaignMutateQuery`
 * hook to handle updates. It also provides localization using `react-i18next`.
 *
 * @returns {JSX.Element} The rendered CampaignEdit component.
 */
const CampaignEdit: React.FC = () => {
  const { t } = useTranslation();
  const { id: campaignKey }: any = useParams();
  const { data: editData, isUpdating }: any = useCampaignFetchQuery({
    id: campaignKey,
  });

  const {
    isUpdated,
    apiSuccessMessage,
    isApiError,
    apiError,
    isGeneralError,
    updateCampaign,
  } = useCampaignMutateQuery();

  /**
   * Handles form submission by invoking the updateCampaign function.
   *
   * @param {ICampaign} data - The updated campaign data submitted through the form.
   */
  const onSubmit = (data: ICampaign): any => {
    updateCampaign(campaignKey, data);
  };

  return (
    <div className="max-w-4xl p-5 mx-auto my-10 bg-white rounded-md shadow-sm">
      <CampaignForm
        isPending={isUpdating}
        editData={editData}
        campaignKey={campaignKey}
        onSubmit={onSubmit}
        isApiError={isApiError}
        apiError={apiError}
        isSuccess={isUpdated}
        isGeneralError={isGeneralError}
        apiSuccessMessage={apiSuccessMessage}
        formTitle={t("campaignEdit.formTitle")}
        submitButton={t("campaignEdit.formTitle")}
      />
    </div>
  );
};

export default CampaignEdit;

import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import envConfig from "../../config/envConfig";
// Default config for the axios instance
const axiosParams = {
  // Set different base URL based on the environment
  baseURL: envConfig.apiBaseUrl,
};

// Create axios instance with default params
export const axiosInstance = axios.create(axiosParams);
// use Interceptors If needed as below
axiosInstance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token"); // Assuming the token is stored in local storage
    if (token) {
      request.headers["Authorization"] = `Bearer ${token}`;
    }
    request.headers["user_id"] = "1";
    request.headers["tenant_id"] = "1";

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // ex: if status === 401 you can redirect the user to login page
      console.error("Response error:", error.response.data);
      console.error("Status code:", error.response.status);
      console.error("Headers:", error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Request error:", error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error:", error.message);
    }
    return Promise.reject(error);
  }
);

// Main api function
const api = (axios: AxiosInstance) => {
  return {
    get: <T>(url: string, config: AxiosRequestConfig = {}) =>
      axios.get<T>(url, config),
    delete: <T>(url: string, config: AxiosRequestConfig = {}) =>
      axios.delete<T>(url, config),
    post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      axios.post<T>(url, body, config),
    patch: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      axios.patch<T>(url, body, config),
    put: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      axios.put<T>(url, body, config),
  };
};

export default api(axiosInstance);

// React and external library imports
import React from "react";
import { Pagination } from "flowbite-react";
// Application-specific imports
import { IPaginationProps } from "../../common/interfaces/IForm";

/**
 * Paginations component provides pagination controls for navigating through pages.
 *
 * @component
 * @param {IPaginationProps} props - The properties for the Paginations component.
 * @param {number} props.currentPage - The current active page number.
 * @param {number} props.totalPages - The total number of pages available.
 * @param {(page: number) => void} props.onChange - Callback function to handle page changes.
 * @returns {JSX.Element} The rendered Paginations component.
 */
export const Paginations: React.FC<IPaginationProps> = ({
  onChange,
  currentPage,
  totalPages,
}) => (
  <div className="flex overflow-x-auto sm:justify-center">
    <div className="flex overflow-x-auto sm:justify-center">
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => onChange(page)}
        showIcons
      />
    </div>
  </div>
);

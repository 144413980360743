import campaign from "./campaign.json";
import common from "./common.json";
import layout from "./layout.json";
import shortUrl from "./shortUrl.json";

const translation = {
  ...campaign,
  ...common,
  ...layout,
  ...shortUrl,
};

export default translation;

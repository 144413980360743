// React and external library imports
import React from "react";
import { Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";
// Application-specific imports
import {
  ISortDropdownProps,
  ISortOption,
} from "../../../common/interfaces/IForm";

/**
 * Renders a dropdown menu for selecting sorting options.
 *
 * Displays and updates sorting criteria based on column (e.g., "Date", "Title") and type (e.g., "Asc", "Desc").
 * The current sorting option is shown as the dropdown label.
 *
 * @param {ISortDropdownProps} props - Component properties.
 * @param {ISortOption} props.sortBy - Current sorting option with column and type.
 * @param {(column: string, type: string) => void} props.onClickSortBy - Callback for sorting option selection.
 *
 * @returns {JSX.Element} The rendered dropdown component.
 *
 * @example
 * <TwSortDropdown
 *   sortBy={{ column: "Date", type: "Desc" }}
 *   onClickSortBy={(column, type) => console.log(`Sorting by ${column} ${type}`)}
 * />
 */
export const TwSortDropdown: React.FC<ISortDropdownProps> = ({
  sortBy,
  onClickSortBy,
}) => {
  const { t } = useTranslation();
  const sortOptions: ISortOption[] = [
    {
      column: "Date",
      type: "Desc",
      label: t("sort.options.dateDesc"),
    },
    { column: "Date", type: "Asc", label: t("sort.options.dateAsc") },
    {
      column: "Title",
      type: "Desc",
      label: t("sort.options.titleDesc"),
    },
    {
      column: "Title",
      type: "Asc",
      label: t("sort.options.titleAsc"),
    },
  ];

  return (
    <Dropdown
      label={
        <span className="text-sm font-medium">
          {t("sort.text", {
            sortBy: sortBy.column + " " + sortBy.type,
          })}
        </span>
      }
      inline
    >
      {sortOptions.map((sortOption) => (
        <Dropdown.Item
          key={`${sortOption.column}-${sortOption.type}`}
          className={
            sortBy.column === sortOption.column &&
            sortBy.type === sortOption.type
              ? "font-bold"
              : ""
          }
          onClick={() => onClickSortBy(sortOption.column, sortOption.type)}
        >
          {sortOption.label}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};

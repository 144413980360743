// React and external library imports
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  Datepicker,
  Label,
  Select,
  TextInput,
  ToggleSwitch,
} from "flowbite-react";
import { IShortUrl, IShortUrlFormProps } from "../../common/interfaces/IForm";
import { route } from "../../common/constant/route";
// Application-specific imports
import { formatDate, objToReactSelect } from "../../common/helpers/helper";
import { TwTitle, TwButton, TwInlineError, TwTags } from "../shared/tw";
import { useTagFetchQuery } from "../../queries/useTagQuery";
import { tagType } from "../../common/constant/common";

/**
 * ShortUrlForm component provides a form for creating or editing a short URL.
 * It uses react-hook-form for form management and Flowbite components for UI elements.
 *
 * @param {IShortUrlFormProps} props - The props for the ShortUrlForm component.
 * @returns {JSX.Element} The rendered ShortUrlForm component.
 */
const ShortUrlForm: React.FC<IShortUrlFormProps> = ({
  onSubmit,
  isApiError,
  isSuccess,
  isGeneralError,
  apiError,
  apiSuccessMessage,
  formTitle,
  submitButton,
  campaignKey,
  editData,
  isPending,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alias, setAlias] = useState("");
  const [switchQr, setSwitchQr] = useState(true);
  const [selectedDate, setSelectedDate] = useState("");
  const [clickLimit, setClickLimit] = useState("");
  const [defaultTags, setDefaultTags] = useState([]);
  const { tags }: any = useTagFetchQuery(tagType.shorturl);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    setFocus,
    reset,
  } = useForm<IShortUrl>({
    defaultValues: {
      generate_qr: switchQr,
      tags: [],
    },
  });
  /**
   * Handles the cancel button click by resetting the form and navigating to the campaign selection page.
   */
  const handleCancelClick = () => {
    reset();
    navigate(route.campaign.selectId(campaignKey));
  };

  /**
   * Handles date changes for the expiration date picker.
   *
   * @param {Date} date - The selected date.
   */
  const handleDateChange = (date: any) => {
    setSelectedDate(formatDate(date, "DD/MM/YYYY"));
    setValue("expiration_date", date);
  };

  /**
   * Handles changes to the QR code toggle switch.
   *
   * @param {boolean} value - The new value of the toggle switch.
   */
  const handleQrToggleChange = (value: boolean) => {
    setSwitchQr(value);
    setValue("generate_qr", value);
  };

  /**
   * Handles changes to the alias input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event from the alias input.
   */
  const handleAliasChange = (event: any) => {
    setAlias(event.target.value);
    setValue("alias", event.target.value);
    clearErrors();
  };

  /**
   * Handles changes to the click limit input field.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The change event from the click limit input.
   */
  const handleClickLimit = (event: any) => {
    const inputValue = event.target.value;
    if (inputValue >= 0 || inputValue === "") {
      setClickLimit(inputValue);
    }
  };

  /**
   * Handles changes to the tags input field.
   *
   * @param {Array<{ value: string, __isNew__: boolean }>} data - The new tag values.
   */
  const handleTagChange = (data: any) => {
    const output = data.map((item: any) => ({
      value: item.value,
      isNew: !!item.__isNew__,
    }));
    setValue("tags", output);
  };

  /**
   * Effect to handle API errors and set focus on the alias field if there's an API error
   * and it's not a general error. Also, sets a custom error message for the alias field.
   */
  useEffect(() => {
    if (isApiError && !isGeneralError) {
      setFocus("alias");
      setError("alias", { type: "custom", message: apiError });
    }
  }, [isApiError, isGeneralError, apiError]);

  /**
   * Effect to show a success toast message and navigate to the campaign selection page
   * if the form submission is successful.
   */
  useEffect(() => {
    if (isSuccess) {
      toast.success(apiSuccessMessage);
      navigate(route.campaign.selectId(campaignKey));
    }
  }, [isSuccess]);

  /**
   * Effect to update the form value for campaign_key whenever the campaignKey prop changes.
   */
  useEffect(() => {
    if (campaignKey) {
      setValue("campaign_key", campaignKey);
    }
  }, [campaignKey]);

  /**
   * Effect to show a general error toast message if a general error occurs.
   */
  useEffect(() => {
    if (isGeneralError) {
      toast.error(t("error.general"));
    }
  }, [isGeneralError]);

  /**
   * Effect to populate form fields with editData if provided. It sets the default values for
   * various fields like target_url, alias, title, etc. and updates the state accordingly.
   */
  useEffect(() => {
    if (editData) {
      const tags = objToReactSelect(editData.tags);

      setValue("target_url", editData.target_url);
      setValue("alias", editData.alias.name);
      setAlias(editData.alias.name);
      setValue("title", editData.title);
      setValue("utm", editData.utm);
      handleQrToggleChange(editData.generate_qr);
      setDefaultTags(tags);
      setValue("tags", tags);

      if (editData.click_limit && editData.click_limit !== -1) {
        setClickLimit(editData.click_limit);
        setValue("click_limit", editData.click_limit);
      }

      if (editData.expiration_date) {
        setSelectedDate(formatDate(editData.expiration_date, "DD/MM/YYYY"));
        setValue("expiration_date", editData.expiration_date);
      }
    }
  }, [editData]);

  return (
    <div className="max-w-4xl px-5 mx-auto my-10 bg-white rounded-md">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TwTitle title={formTitle} borderBottom={true} />

        <div className="col-span-1 md:col-span-2 mb-6">
          <Label
            htmlFor="title"
            value={t("shortUrlForm.title.label")}
            className="text-base"
          />
          <div className="mt-2">
            <TextInput
              color={errors?.title && "failure"}
              id="title"
              {...register("title", {
                maxLength: 50,
              })}
            />
            {errors?.title && (
              <TwInlineError error={t("shortUrlForm.title.error")} />
            )}
          </div>
        </div>

        <div className="col-span-1 md:col-span-2">
          <Label
            htmlFor="target-url"
            value={t("shortUrlForm.targetUrl.label")}
            className="text-base"
          />
          <div className="mt-2">
            <TextInput
              color={errors?.target_url && "failure"}
              id="target-url"
              placeholder={t("shortUrlForm.targetUrl.placeholder")}
              {...register("target_url", {
                required: true,
                pattern: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i,
              })}
            />
            {errors?.target_url && (
              <TwInlineError error={t("shortUrlForm.targetUrl.error")} />
            )}
          </div>
        </div>

        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="col-span-1">
            <Label
              htmlFor="domain"
              value={t("shortUrlForm.domain.label")}
              className="text-base"
            />
            <div className="mt-2">
              <Select id="domain" disabled>
                <option>{t("shortUrlForm.domain.text")}</option>
              </Select>
            </div>
          </div>

          <div className="col-span-1">
            <Label
              htmlFor="alias"
              value={t("shortUrlForm.alias.label")}
              className="text-base"
            />
            <div className="mt-2">
              <TextInput
                color={errors?.alias && "failure"}
                id="alias"
                {...register("alias", {
                  maxLength: 50,
                })}
                value={alias}
                onChange={handleAliasChange}
                name="alias"
              />
              {/* <TextInput
                    color={aliasErrorType} 
                    id="alias" 
                    {...register("alias", {
                      maxLength: 50,
                    })}
                    onChange={handleAliasChange}
                    name="alias" 
                    ref={(e) => {
                      ref(e)
                      aliasRef.current = e // you can still assign to ref
                    }}  
                  /> */}
              {errors?.alias?.type === "maxLength" && (
                <TwInlineError error={t("shortUrlForm.alias.error")} />
              )}
              {errors?.alias?.type === "custom" && (
                <TwInlineError error={errors?.alias?.message} />
              )}
            </div>
          </div>
        </div>

        <TwTitle
          title={t("shortUrlForm.advanceOption")}
          borderBottom={true}
          className="mt-5"
        />

        <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="col-span-1">
            <Label
              htmlFor="expiration-date"
              value={t("shortUrlForm.expirationDate.label")}
              className="text-base"
            />
            <div className="mt-2">
              <Datepicker
                placeholder={t("shortUrlForm.expirationDate.placeholder")}
                id="expiration-date"
                {...register("expiration_date")}
                value={selectedDate}
                showClearButton={true}
                showTodayButton={false}
                onSelectedDateChanged={handleDateChange}
                minDate={new Date()}
              />
            </div>
          </div>
          <div className="col-span-1">
            <Label
              htmlFor="click-limit"
              value={t("shortUrlForm.clickLimit.label")}
              className="text-base"
            />
            <div className="mt-2">
              <TextInput
                color={errors?.click_limit && "failure"}
                id="click-limit"
                type="number"
                {...register("click_limit", {
                  minLength: 1,
                  maxLength: 5,
                })}
                value={clickLimit}
                onChange={handleClickLimit}
              />
              {errors?.click_limit && (
                <TwInlineError error={t("shortUrlForm.clickLimit.error")} />
              )}
            </div>
          </div>
        </div>

        <div className="mt-5 col-span-1 md:col-span-2">
          <Label
            htmlFor="tags"
            value={t("shortUrlForm.tags.label")}
            className="text-base"
          />
          <div className="mt-2">
            {defaultTags.length > 0 && tags && (
              <TwTags
                options={objToReactSelect(tags)}
                defaultTags={defaultTags}
                onChange={handleTagChange}
              />
            )}
            {defaultTags.length === 0 && tags && (
              <TwTags
                options={objToReactSelect(tags)}
                onChange={handleTagChange}
              />
            )}
          </div>
        </div>

        <div className="mt-5 col-span-1 md:col-span-2">
          <Label
            htmlFor="utm"
            value={t("shortUrlForm.utm.label")}
            className="text-base"
          />
          <div className="mt-2">
            <TextInput
              color={errors?.utm && "failure"}
              placeholder={t("shortUrlForm.utm.placeholder")}
              id="utm"
              {...register("utm", {
                maxLength: 50,
                pattern: /^(utm_[a-z]+=[^&]+(&utm_[a-z]+=[^&]+)*)$/i,
              })}
            />
            {errors?.utm?.type === "maxLength" && (
              <TwInlineError error={t("shortUrlForm.utm.error.maxLength")} />
            )}
            {errors?.utm?.type === "pattern" && (
              <TwInlineError error={t("shortUrlForm.utm.error.pattern")} />
            )}
          </div>
        </div>

        <div className="mt-5 col-span-1 md:col-span-2">
          <Label
            htmlFor="generate-qr"
            value={t("shortUrlForm.generateQr.label")}
            className="text-base"
          />
          <div className="mt-2">
            <ToggleSwitch
              checked={switchQr}
              label={t("shortUrlForm.generateQr.toggle.label")}
              onChange={handleQrToggleChange}
            />
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-end mt-5 space-y-4 md:space-y-0 md:space-x-4">
          <TwButton
            title={t("shortUrlForm.button.cancel")}
            color="zinc"
            className="w-full md:w-auto"
            onClick={handleCancelClick}
          />
          <TwButton
            title={submitButton}
            className="w-full md:w-auto"
            type="submit"
            disabled={isPending}
          />
        </div>
      </form>
    </div>
  );
};

export default ShortUrlForm;

//External library imports
import { useQuery } from "@tanstack/react-query";
// Application-specific imports
import apiService from "../services/api/apiService";
import { objToQueryParams } from "../common/helpers/helper";
import { api } from "../common/constant/api";

/**
 * Fetches tags based on the provided parameters.
 * @param {object} [params={}] - The query parameters for fetching tags.
 * @returns {Promise<any>} - The fetched tag data.
 */
const fetchTags = async (params: object = {}): Promise<any> => {
  const response = await apiService.get(api.tags.get(objToQueryParams(params)));
  if (response.data) {
    return response.data;
  } else {
    return [];
  }
};
/**
 * Custom hook to fetch tags based on a specific type.
 * @param {number} type - The type of tags to fetch.
 * @returns {object} - The query result including tags, loading, error, and refetch functions.
 */
export const useTagFetchQuery = (type: number) => {
  let result = null;

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["tags", type],
    queryFn: () => fetchTags({ type }),
  });

  if (data && "data" in data) {
    result = data.data;
  }

  return {
    tags: result,
    isLoading,
    isError,
    refetch,
  };
};

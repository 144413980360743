// React and external library imports
import React from "react";
// Application-specific imports
import { ITitleProps } from "../../../common/interfaces/IForm";

/**
 * `TwTitle` displays a customizable title with optional bottom border.
 *
 * @param {ITitleProps} props - Component properties.
 * @param {string} props.title - The title text to display.
 * @param {boolean} [props.borderBottom=false] - Whether to display a bottom border.
 * @param {string} [props.className=""] - Additional CSS classes.
 * @param {() => void} [props.onClick] - Optional click handler.
 *
 * @returns {JSX.Element} The rendered `TwTitle` component.
 *
 * @example
 * <TwTitle
 *   title="Page Title"
 *   borderBottom
 *   className="my-custom-class"
 *   onClick={() => console.log('Title clicked')}
 * />
 */
export const TwTitle: React.FC<ITitleProps> = ({
  title,
  borderBottom = false,
  className = "",
  onClick,
}) => (
  <h2
    onClick={onClick}
    className={`text-lg font-bold font-poppins text-gray-700 ${
      borderBottom ? "border-b pb-5 mb-6" : ""
    } ${className}`}
  >
    {title}
  </h2>
);

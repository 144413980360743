// React and external library imports
import React, { useEffect } from "react";
import { Tooltip } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import {
  TwTitle,
  TwBadge,
  TwInlineDate,
  TwActionItems,
  TwLink,
} from "../shared/tw";
import Swal from "sweetalert2";
// Icons
import { IoPricetagsOutline, IoTimeOutline } from "react-icons/io5";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { IoQrCodeOutline } from "react-icons/io5";
import { TbHandClick } from "react-icons/tb";
// Application-specific imports
import { formatDate } from "../../common/helpers/helper";
import { useShortUrlMutateQuery } from "../../queries/useShortUrlQuery";
import { route } from "../../common/constant/route";
import { tagMaxShow } from "../../common/constant/common";

/**
 * ShortUrlCard component displays details of a short URL with options to edit or delete.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.url - The short URL data.
 * @param {string} props.campaignKey - The key for the campaign associated with the URL.
 * @returns {JSX.Element} The rendered ShortUrlCard component.
 */
const ShortUrlCard: React.FC<any> = ({ url, campaignKey }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDeleted, deleteShortUrl, apiSuccessMessage }: any =
    useShortUrlMutateQuery(campaignKey);

  const shortUrl = `${url.domain.name}/${url.alias.name}`;
  /**
   * Navigates to the edit page for the short URL.
   */
  const handleEditShortUrl = () => {
    navigate(route.link.editId(campaignKey, url.id));
  };
  /**
   * Handles the deletion of a short URL with a confirmation prompt.
   */
  const handleDeleteShortUrl = () => {
    const options: any = {
      title: t("shortUrlCard.alert.title"),
      text: t("shortUrlCard.alert.text"),
      icon: t("shortUrlCard.alert.icon"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("shortUrlCard.alert.confirmButtonText"),
    };

    Swal.fire(options).then((result) => {
      if (result.isConfirmed) {
        deleteShortUrl(url.id);
      }
    });
  };
  // Actions available for the short URL card
  const actions = [
    {
      icon: MdModeEdit,
      title: t("actions.edit"),
      onclick: handleEditShortUrl,
    },
    {
      icon: MdDelete,
      title: t("actions.delete"),
      onclick: handleDeleteShortUrl,
    },
  ];

  useEffect(() => {
    if (isDeleted) {
      toast.success(apiSuccessMessage);
    }
  }, [isDeleted, apiSuccessMessage]);

  return (
    <div className="col-span-1 md:col-span-2" key={shortUrl}>
      <div className="bg-white shadow-md rounded-lg px-7 py-5">
        <div className="flex justify-between items-center mb-2">
          <TwTitle title={url && url.title} className="capitalize" />
          <TwActionItems actions={actions} />
        </div>
        <div className="flex flex-col md:flex-row justify-between gap-2 items-start md:items-center border-b border-blue-400 pb-4">
          <div className="flex flex-col md:flex-row flex-grow">
            <Tooltip content={t("shortUrlCard.tooltip.shortUrl")}>
              <TwLink className="md:mr-4" color="blue" url={shortUrl} />
            </Tooltip>
            <Tooltip content={t("shortUrlCard.tooltip.targetUrl")}>
              <TwLink url={url.target_url} className="truncated-url" />
            </Tooltip>
          </div>
        </div>

        <div className="flex flex-wrap gap-2 mt-4 capitalize">
          <div className="inline-flex">
            {url.created_at && (
              <TwInlineDate
                title={t("shortUrlCard.badge.createdDate", {
                  date: formatDate(url.created_at),
                })}
              />
            )}
          </div>
          <div className="inline-flex">
            <TwBadge
              className="bg-gray-200"
              icon={TbHandClick}
              title={
                url.click_limit !== -1
                  ? t("shortUrlCard.badge.limitAccess", {
                      click_limit: url.click_limit,
                    })
                  : t("shortUrlCard.badge.unlimitedAccess")
              }
            />
          </div>
          <div className="inline-flex">
            <TwBadge
              className="bg-gray-200"
              icon={IoQrCodeOutline}
              title={
                url.generate_qr
                  ? t("shortUrlCard.badge.qrAvailable")
                  : t("shortUrlCard.badge.qrNotAvailable")
              }
            />
          </div>
          <div className="inline-flex">
            <TwBadge
              className="text-white bg-red-500"
              icon={IoTimeOutline}
              title={
                url.expiration_date
                  ? t("shortUrlCard.badge.expirySet", {
                      date: formatDate(url.expiration_date),
                    })
                  : t("shortUrlCard.badge.expiryNotSet")
              }
            />
          </div>
          <div className="inline-flex">
            {url.tags?.length > 0 && (
              <>
                <IoPricetagsOutline className="ml-2 mr-2 h-5 w-5 flex-shrink-0" />
                {url.tags
                  .slice(0, tagMaxShow)
                  .map((tag: any, index: number) => (
                    <TwBadge
                      key={index}
                      title={tag.value}
                      className="mr-1 mb-1 text-white flex-shrink-0 bg-blue-500 pb-1"
                    />
                  ))}
                {url.tags.length > tagMaxShow && (
                  <Tooltip
                    placement="right"
                    className="text-xs"
                    content={
                      <div className="flex flex-col">
                        {url.tags
                          .slice(tagMaxShow)
                          .map((tag: any, index: number) => (
                            <p key={index} className="text-gray-200">
                              {tag.value}
                            </p>
                          ))}
                      </div>
                    }
                  >
                    <TwBadge
                      title={t("shortUrlCard.badge.more", {
                        count: url.tags.length - tagMaxShow,
                      })}
                      className="mr-2 mb-1 text-gray-600 flex-shrink-0"
                    />
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShortUrlCard;

// React and external library imports
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Label, TextInput, Textarea } from "flowbite-react";
// Application-specific imports
import { route } from "../../common/constant/route";
import { tagType } from "../../common/constant/common";
import { useTagFetchQuery } from "../../queries/useTagQuery";
import { objToReactSelect } from "../../common/helpers/helper";
import { TwButton, TwTitle, TwInlineError, TwTags } from "../shared/tw";
import { ICampaign, ICampaignFormProps } from "../../common/interfaces/IForm";

/**
 * CampaignForm component provides a form for creating or editing a campaign.
 *
 * @component
 * @example
 * return <CampaignForm
 *   onSubmit={handleFormSubmit}
 *   isApiError={false}
 *   apiError=""
 *   isPending={false}
 *   isSuccess={true}
 *   apiSuccessMessage="Campaign updated successfully!"
 *   isGeneralError={false}
 *   formTitle="Edit Campaign"
 *   submitButton="Save Changes"
 *   editData={campaignData}
 * />;
 *
 * This component utilizes React Hook Form for form management, Flowbite for UI components,
 * and provides functionality for handling form submission, validation errors, and state management.
 *
 * @param {ICampaignFormProps} props - The props for the CampaignForm component.
 * @param {Function} props.onSubmit - Function to call on form submission.
 * @param {boolean} props.isApiError - Indicates if there was an API error.
 * @param {string} props.apiError - The error message from the API.
 * @param {boolean} props.isPending - Indicates if the form submission is in progress.
 * @param {boolean} props.isSuccess - Indicates if the form submission was successful.
 * @param {string} props.apiSuccessMessage - The success message from the API.
 * @param {boolean} props.isGeneralError - Indicates if there is a general error.
 * @param {string} props.formTitle - The title of the form.
 * @param {string} props.submitButton - The text for the submit button.
 * @param {ICampaign} [props.editData] - Optional campaign data for editing.
 *
 * @returns {JSX.Element} The rendered CampaignForm component.
 */
const CampaignForm: React.FC<ICampaignFormProps> = ({
  onSubmit,
  isApiError,
  apiError,
  isPending,
  isSuccess,
  apiSuccessMessage,
  isGeneralError,
  formTitle,
  submitButton,
  editData,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [defaultTags, setDefaultTags] = useState([]);

  const { tags }: any = useTagFetchQuery(tagType.campaign);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
    setError,
    setValue,
  } = useForm<ICampaign>({
    defaultValues: {
      name: "",
      description: "",
      tags: [],
    },
  });
  /**
   * Handles the cancel button click by navigating to the campaign list.
   */
  const handleCancelClick = () => {
    navigate(route.campaign.lists);
  };
  /**
   * Handles the change of tags by updating the form value with selected tags.
   *
   * @param {any[]} data - The array of selected tag objects.
   */
  const handleTagChange = (data: any) => {
    const output = data.map((item: any) => ({
      value: item.value,
      isNew: !!item.__isNew__,
    }));
    setValue("tags", output);
  };

  useEffect(() => {
    // Show a success message and navigate to the campaign list if the form submission is successful
    if (isSuccess) {
      toast.success(apiSuccessMessage);
      navigate(route.campaign.lists);
    }
  }, [isSuccess, navigate, apiSuccessMessage]);

  useEffect(() => {
    // Set focus on the name input field and show a custom error message if there is an API error and it's not a general error
    if (isApiError && !isGeneralError) {
      setFocus("name");
      setError("name", { type: "custom", message: apiError });
    }
  }, [isApiError, setFocus, setError, apiError, isGeneralError]);

  useEffect(() => {
    // Show a general error message if there is a general error
    if (isGeneralError) {
      toast.error(t("error.general"));
    }
  }, [isGeneralError, t]);

  useEffect(() => {
    // Pre-fill form fields with edit data if provided, and set default tags
    if (editData) {
      const tags = objToReactSelect(editData.tags);

      setValue("name", editData.name);
      setValue("description", editData.description);
      setDefaultTags(tags);
      setValue("tags", tags);
    }
  }, [editData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TwTitle title={formTitle} borderBottom={true} />

      <div className="mt-5 col-span-1 md:col-span-2">
        <Label
          htmlFor="name"
          value={t("campaignForm.name.label")}
          className="text-base"
        />
        <div className="mt-2">
          <TextInput
            color={errors?.name && "failure"}
            id="name"
            placeholder={t("campaignForm.name.placeholder")}
            {...register("name", {
              required: true,
              maxLength: 50,
            })}
          />
          {errors?.name?.type === "required" && (
            <TwInlineError error={t("campaignForm.name.error.required")} />
          )}
          {errors?.name?.type === "maxLength" && (
            <TwInlineError error={t("campaignForm.name.error.maxLength")} />
          )}
          {errors?.name?.type === "custom" && (
            <TwInlineError error={errors?.name?.message} />
          )}
        </div>
      </div>

      <div className="mt-5 col-span-1 md:col-span-2">
        <Label
          htmlFor="description"
          value={t("campaignForm.description.label")}
          className="text-base"
        />
        <div className="mt-2">
          <Textarea
            id="description"
            rows={4}
            {...register("description", {
              maxLength: 300,
            })}
          />
          {errors?.description?.type === "maxLength" && (
            <TwInlineError
              error={t("campaignForm.description.error.maxLength")}
            />
          )}
        </div>
      </div>

      <div className="mt-5 col-span-1 md:col-span-2">
        <Label
          htmlFor="tags"
          value={t("campaignForm.tags.label")}
          className="text-base"
        />
        <div className="mt-2">
          {defaultTags.length > 0 && tags && (
            <TwTags
              options={objToReactSelect(tags)}
              defaultTags={defaultTags}
              onChange={handleTagChange}
            />
          )}
          {defaultTags.length === 0 && tags && (
            <TwTags
              options={objToReactSelect(tags)}
              onChange={handleTagChange}
            />
          )}
        </div>
      </div>

      <div className="flex flex-col md:flex-row justify-end mt-5 space-y-4 md:space-y-0 md:space-x-4">
        <TwButton
          title={t("campaignForm.button.cancel")}
          color="zinc"
          className="w-full md:w-auto"
          onClick={handleCancelClick}
        />
        <TwButton
          disabled={isPending}
          title={submitButton}
          className="w-full md:w-auto"
          type="submit"
        />
      </div>
    </form>
  );
};

export default CampaignForm;

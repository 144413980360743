// React and external library imports
import React from "react";
import type { CustomFlowbiteTheme } from "flowbite-react";
import { Badge } from "flowbite-react";
// Application-specific imports
import { ITwBadge } from "../../../common/interfaces/IForm";

/**
 * TwBadge component displays a styled badge with optional icon and click handler.
 *
 * This component utilizes Flowbite's Badge component with a custom theme for styling.
 * It allows for the inclusion of an icon and a click handler, and it can be customized
 * with additional CSS class names.
 *
 * @param {ITwBadge} props - The properties for the TwBadge component.
 * @param {string} props.title - The text to display inside the badge.
 * @param {string} [props.className] - Optional additional CSS class names to apply to the badge.
 * @param {React.ReactNode} [props.icon] - Optional icon to display within the badge.
 * @param {() => void} [props.onClick] - Optional click handler function to be called when the badge is clicked.
 *
 * @returns {JSX.Element} The rendered TwBadge component.
 *
 * @example
 * <TwBadge
 *   title="New"
 *   icon={<NewIcon />}
 *   onClick={() => console.log('Badge clicked')}
 *   className="bg-blue-500 text-white"
 * />
 */
const customTheme: CustomFlowbiteTheme["badge"] = {
  icon: {
    size: {
      xs: "mr-1 h-4 w-4",
    },
  },
};

export const TwBadge: React.FC<ITwBadge> = ({
  title,
  className,
  icon,
  onClick,
}) => (
  <div className="flex-shrink-0 flex">
    <Badge
      onClick={onClick}
      theme={customTheme}
      icon={icon}
      className={`rounded-md p-1 px-2 capitalize ${className}`}
      color="gray"
    >
      {title}
    </Badge>
  </div>
);

// React and React Router imports
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Application-specific imports
import { Layout } from "./components/layout/Layout";
import { appRoutes, route } from "./common/constant/route";
import "./i18n/config";
import Home from "./components/home/Home";
import AuthRoute from "./components/shared/AuthRoute";

/**
 * The main application component that sets up routing.
 *
 * @component
 * @example
 * return <App />;
 *
 * This component uses React Router to define routes for the application.
 * It wraps all routes inside a `Router` and uses a `Layout` component for
 * the main layout structure. Individual routes are mapped from the `appRoutes`
 * configuration.
 *
 * @returns {JSX.Element} The rendered application component.
 */
const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        {/* Define the root route with Layout as the wrapper */}
        <Route path="/" element={<Home />} index={true} />
        <Route path={route.home} element={<Layout />}>
          {appRoutes.map(({ path, element: Element, index }) => (
            <Route
              key={path}
              path={path}
              element={
                <AuthRoute>
                  <Element />
                </AuthRoute>
              }
              index={index}
            />
          ))}
        </Route>
      </Routes>
    </Router>
  );
};

export default App;

// React and external library imports
import React from "react";
// Application-specific imports
import { IErrorMessage } from "../../../common/interfaces/IForm";

/**
 * TwInlineError component displays an inline error message in red text.
 *
 * This component is used to present error messages with a red color and small text size,
 * typically used for form validation or error notifications.
 *
 * @param {IErrorMessage} props - The properties for the TwInlineError component.
 * @param {string} props.error - The error message to be displayed.
 *
 * @returns {JSX.Element} The rendered TwInlineError component.
 *
 * @example
 * <TwInlineError error="This field is required." />
 */
export const TwInlineError: React.FC<IErrorMessage> = ({ error }) => (
  <span className="text-red-600 text-sm">{error}</span>
);

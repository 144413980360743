//External library imports
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
//Application-specific imports
import { IShortUrl } from "../common/interfaces/IForm";
import apiService from "../services/api/apiService";
import { api } from "../common/constant/api";
import { IApiResponse } from "../common/interfaces/IResponse";
import { objToQueryParams } from "../common/helpers/helper";

/**
 * Fetches short URLs based on the provided campaign key and parameters.
 * @param {string} campaignKey - The key of the campaign to fetch short URLs for.
 * @param {object} [params={}] - The query parameters for fetching short URLs.
 * @returns {Promise<any>} - The fetched short URL data.
 */
const fetchShortUrl = async (
  campaignKey: string,
  params: object = {}
): Promise<any> => {
  const response = await apiService.get(
    api.short_url.get(campaignKey, objToQueryParams(params))
  );
  if (response.data) {
    return response.data;
  } else {
    return [];
  }
};

/**
 * Creates a new short URL.
 * @param {IShortUrl} newShortUrlData - The data for the new short URL.
 * @returns {Promise<boolean>} - The response from the API.
 */
const createShortUrl = async (newShortUrlData: IShortUrl): Promise<boolean> => {
  const response: any = await apiService.post(
    api.short_url.create,
    newShortUrlData
  );
  return response.data;
};

/**
 * Updates an existing short URL.
 * @param {object} params - The update parameters.
 * @param {number} params.shortUrlId - The ID of the short URL to update.
 * @param {IShortUrl} params.updateShortUrlData - The data to update the short URL.
 * @returns {Promise<boolean>} - The response from the API.
 */
const updateShortUrl = async ({
  shortUrlId,
  updateShortUrlData,
}: {
  shortUrlId: number;
  updateShortUrlData: IShortUrl;
}): Promise<boolean> => {
  const response: any = await apiService.put(
    api.short_url.update(shortUrlId),
    updateShortUrlData
  );
  return response.data;
};

/**
 * Deletes a short URL.
 * @param {number} shortUrlId - The ID of the short URL to delete.
 * @returns {Promise<void>} - The response from the API.
 */
const deleteShortUrl = async (shortUrlId: number): Promise<void> => {
  const response: any = await apiService.delete(
    api.short_url.delete(shortUrlId)
  );
  return response.data;
};

/**
 * Custom hook to fetch short URLs with query parameters.
 * @param {string} campaignKey - The key of the campaign to fetch short URLs for.
 * @param {object} [params={}] - The query parameters for fetching short URLs.
 * @returns {object} - The query result including data, loading, error, and refetch functions.
 */
export const useShortUrlFetchQuery = (
  campaignKey: string,
  params: any = {}
) => {
  let result = null;
  let queryKey = ["short-urls"];

  if ("url_id" in params) {
    queryKey.push(params.url_id);
  } else {
    queryKey = [
      ...queryKey,
      ...[params.page, params.per_page, params.sort_by, params.sort_type],
    ];
  }

  const { data, isLoading, isError, refetch }: UseQueryResult<IShortUrl> =
    useQuery({
      queryKey: queryKey,
      queryFn: () => fetchShortUrl(campaignKey, params),
    });

  if (data && "data" in data) {
    result = data.data;
  }

  return {
    data: result,
    isLoading,
    isError,
    refetchShortUrls: refetch,
  };
};

/**
 * Custom hook to handle short URL mutations (create, update, delete).
 * @param {string} campaignKey - The key of the campaign associated with the short URLs.
 * @returns {object} - The mutation handlers and statuses.
 */
export const useShortUrlMutateQuery = (campaignKey: string) => {
  let apiErrorMessage,
    apiSuccessMessage = null;
  let generalError = false;
  const queryClient = useQueryClient();

  //create short url
  const {
    mutate: mutateCreateShortUrl,
    isPending: isCreating,
    error: createError,
    isError: isCreateError,
    isSuccess,
    data: createResponse,
  }: UseMutationResult<boolean, Error, IShortUrl> = useMutation({
    mutationFn: createShortUrl,
  });

  const createShortUrlHandler = async (newShortUrlData: IShortUrl) => {
    return await mutateCreateShortUrl(newShortUrlData);
  };

  //update short url
  const {
    isSuccess: isUpdated,
    isPending: isUpdating,
    isError: isUpdateError,
    error: updateError,
    mutate: mutateUpdateShortUrl,
    data: updateResponse,
  }: UseMutationResult<
    boolean,
    Error,
    { shortUrlId: number; updateShortUrlData: IShortUrl }
  > = useMutation({
    mutationFn: updateShortUrl,
  });

  /**
   * Handles updating an existing short URL.
   * @param {number} shortUrlId - The ID of the short URL to update.
   * @param {IShortUrl} updateShortUrlData - The data to update the short URL.
   * @returns {Promise<void>} - The result of the mutation.
   */
  const updateShortUrlHandler = async (
    shortUrlId: number,
    updateShortUrlData: IShortUrl
  ) => {
    return await mutateUpdateShortUrl({ shortUrlId, updateShortUrlData });
  };

  //delete campaign
  const {
    mutate: mutateDeleteShortUrl,
    data: deleteResponse,
    isSuccess: isDeleted,
  }: UseMutationResult<void, Error, number> = useMutation({
    mutationFn: deleteShortUrl,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["short-urls"] });
    },
  });

  /**
   * Handles deleting a short URL.
   * @param {number} shortUrlId - The ID of the short URL to delete.
   * @returns {Promise<void>} - The result of the mutation.
   */
  const deleteShortUrlHandler = async (shortUrlId: number) => {
    await mutateDeleteShortUrl(shortUrlId);
  };

  if (isSuccess || isDeleted || isUpdated) {
    const response = isSuccess
      ? createResponse
      : isDeleted
      ? deleteResponse
      : isUpdated
      ? updateResponse
      : null;
    if (response) {
      const apiData = response as unknown as IApiResponse;
      apiSuccessMessage = apiData.message;
    }
  }

  if (createError || updateError) {
    const error = createError ?? updateError;
    const apiError = error as any;

    if (apiError.response.status === 409) {
      apiErrorMessage = apiError.response.data.error;
    } else if (apiError.response.status === 500) {
      generalError = true;
    }
  }

  return {
    isSuccess,
    isCreating,
    isDeleted,
    isUpdated,
    isUpdating,
    apiSuccessMessage,
    apiError: apiErrorMessage,
    isGeneralError: generalError,
    isApiError: isCreateError || isUpdateError,
    createShortUrl: createShortUrlHandler,
    deleteShortUrl: deleteShortUrlHandler,
    updateShortUrl: updateShortUrlHandler,
  };
};

// React and external library imports
import React from "react";
import { Breadcrumb } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import useBreadcrumbs, {
  BreadcrumbsRoute,
  BreadcrumbData,
} from "use-react-router-breadcrumbs";
// Application-specific imports
import { route } from "../../common/constant/route";
import Dashboard from "../dashboard/Dashboard";

/**
 * Defines the routes and corresponding breadcrumb labels.
 *
 * @constant {BreadcrumbsRoute[]} routes - An array of route objects used to generate breadcrumbs.
 * @property {string} path - The path pattern for the route.
 * @property {React.ReactNode} breadcrumb - The label to display for this breadcrumb.
 * @property {object} props - Additional properties to associate with the breadcrumb.
 */
const routes: BreadcrumbsRoute[] = [
  {
    path: route.home,
    breadcrumb: "Home",
    props: { type: "home" },
  },
  {
    path: route.dashboard,
    breadcrumb: "Dashboard",
    props: { type: "dashboard" },
  },
  {
    path: "/:id/link",
    breadcrumb: "Short Lists",
    props: { type: "tempIdLInk" },
  },
  {
    path: "/campaign",
    breadcrumb: "Campaign Lists",
    props: { type: "tempCampaign" },
  },
  {
    path: route.link.create,
    breadcrumb: "Create Short Url",
    props: { type: "createShortUrl" },
  },
  {
    path: route.campaign.lists,
    breadcrumb: "Campaign Lists",
    props: { type: "campaigns" },
  },
  {
    path: route.campaign.create,
    breadcrumb: "Create Campaign",
    props: { type: "createCampaign" },
  },
  {
    path: route.campaign.edit,
    breadcrumb: "Edit Camapign",
    props: { type: "editCampaign" },
  },
  {
    path: route.campaign.details,
    breadcrumb: "Short Lists",
    props: { type: "shortUrls" },
  },
  {
    path: route.link.edit,
    breadcrumb: "Edit Short Url",
    props: { type: "editShortUrl" },
  },
];

type BreadcrumbHandler = (
  breadcrumbs: BreadcrumbData<any>[]
) => BreadcrumbData<any>[];

/**
 * Creates a breadcrumb item.
 *
 * @param {string} path - The path for the breadcrumb item.
 * @param {React.ReactNode} breadcrumb - The label for the breadcrumb item.
 * @returns {object} The breadcrumb item object.
 */
const createBreadcrumbItem = (
  path: string,
  breadcrumb: React.ReactNode
): any => ({
  match: { pathname: path },
  breadcrumb,
});

/**
 * Maps the breadcrumbs based on path conditions.
 *
 * @param {BreadcrumbData<any>[]} breadcrumbs - The array of breadcrumb data.
 * @param {object[]} pathConditions - The conditions for mapping breadcrumbs.
 * @param {string} pathConditions.condition - The path condition to match.
 * @param {string|function} pathConditions.path - The path or a function to generate the path.
 * @returns {BreadcrumbData<any>[]} The mapped array of breadcrumb data.
 */
const mapBreadcrumbs = (
  breadcrumbs: BreadcrumbData<any>[],
  pathConditions: {
    condition: string;
    path: string | ((match: any) => string);
  }[]
): BreadcrumbData<any>[] => {
  return breadcrumbs
    .map(({ match, breadcrumb }) => {
      const tempRoute: BreadcrumbData<any>[] = [];

      pathConditions.forEach(({ condition, path }) => {
        if (match.pattern.path === condition) {
          tempRoute.push(
            createBreadcrumbItem(
              typeof path === "function" ? path(match) : path,
              breadcrumb
            )
          );
        }
      });

      return tempRoute;
    })
    .flat();
};

/**
 * Defines handlers for different breadcrumb types.
 *
 * @constant {Record<string, BreadcrumbHandler>} breadcrumbHandlers - An object mapping breadcrumb types to handlers.
 */
const breadcrumbHandlers: Record<string, BreadcrumbHandler> = {
  dashboard: (breadcrumbs) =>
    mapBreadcrumbs(breadcrumbs, [{ condition: "/dashboard", path: "" }]),
  campaigns: (breadcrumbs) =>
    mapBreadcrumbs(breadcrumbs, [{ condition: "/campaigns", path: "" }]),
  editCampaign: (breadcrumbs) =>
    mapBreadcrumbs(breadcrumbs, [
      { condition: "/campaign", path: "/campaigns" },
      { condition: "/campaign/edit/:id", path: (match) => match.pathname },
    ]),
  createCampaign: (breadcrumbs) =>
    mapBreadcrumbs(breadcrumbs, [{ condition: "/campaign/create", path: "" }]),
  shortUrls: (breadcrumbs) =>
    mapBreadcrumbs(breadcrumbs, [
      { condition: "/campaign", path: "/campaigns" },
      { condition: "/campaign/:id", path: "" },
    ]),
  createShortUrl: (breadcrumbs) =>
    mapBreadcrumbs(breadcrumbs, [
      {
        condition: "/:id/link",
        path: (match) => route.campaign.selectId(match.params.id),
      },
      { condition: "/:id/link/create", path: "" },
    ]),
  editShortUrl: (breadcrumbs) =>
    mapBreadcrumbs(breadcrumbs, [
      {
        condition: "/:id/link",
        path: (match) => route.campaign.selectId(match.params.id),
      },
      { condition: "/:campaign_key/link/edit/:id", path: "" },
    ]),
};

/**
 * Filters breadcrumbs based on the last breadcrumb type.
 *
 * @param {any[]} breadcrumbs - The array of breadcrumb data.
 * @returns {BreadcrumbData<any>[]} The filtered array of breadcrumb data.
 */
const filterBreadcrumbs = (breadcrumbs: any[]) => {
  const lastObject = breadcrumbs[breadcrumbs.length - 1];
  const handler = breadcrumbHandlers[lastObject.type];
  return handler ? handler(breadcrumbs) : [];
};

/**
 * BreadcrumbNav component renders a breadcrumb navigation based on the current route.
 *
 * @component
 * @returns {JSX.Element} The rendered BreadcrumbNav component.
 */
export const BreadcrumbNav: React.FC = () => {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs(routes);
  const filteredRoutes = filterBreadcrumbs(breadcrumbs);

  /**
   * Handles navigation to a specified path.
   *
   * @param {string} path - The path to navigate to.
   */
  const handleNavigate = (path: string) => {
    if (path) navigate(path);
  };

  return (
    <div className="col-span-1 md:col-span-2">
      <div className="bg-slate-50 px-7 py-4">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <Breadcrumb aria-label="Default breadcrumb example">
            {filteredRoutes.map(({ match, breadcrumb }) => (
              <Breadcrumb.Item key={match.pathname}>
                <span
                  onClick={() => handleNavigate(match.pathname)}
                  className={`capitalize ${
                    match.pathname ? "hover:cursor-pointer" : ""
                  }`}
                >
                  {breadcrumb}
                </span>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </div>
      </div>
    </div>
  );
};

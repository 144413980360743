/**
 * Configuration object for environment-specific settings.
 * This object provides configuration values such as API base URL and Auth0 credentials.
 *
 * @constant {IEnvConfig} envConfig - The environment configuration object.
 *
 * @property {string} apiBaseUrl - The base URL for the API, sourced from the environment variable `REACT_APP_API_BASE_URL`.
 * @property {string} auth0Domain - The domain for Auth0 authentication, defaulting to "dev-nav.us.auth0.com" if not set in the environment.
 * @property {string} auth0ClientId - The client ID for Auth0 authentication, defaulting to "P2ULS9KgCHM1FGLGeVaTvMqvKTbFsemp" if not set in the environment.
 * @property {string} auth0RedirectUri -
 */

import { IEnvConfig } from "./../common/interfaces/ICommon";

const envConfig: IEnvConfig = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  auth0Domain: process.env.AUTH_0_DOMAIN || "dev-nav.us.auth0.com",
  auth0ClientId:
    process.env.AUTH_0_CLIENTID || "P2ULS9KgCHM1FGLGeVaTvMqvKTbFsemp",
  auth0RedirectUri: `${window.location.origin}/dashboard`,
};

export default envConfig;

// React and React Router imports
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// UI components and icons
import { IoFilterSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
import { Button } from "flowbite-react";
// Custom components and hooks
import CampaignCard from "./CampaignCard";
import { useCampaignFetchQuery } from "../../queries/useCampaignQuery";
import { useTagFetchQuery } from "../../queries/useTagQuery";
import { Paginations } from "../shared/Paginations";
import { NoData } from "../shared/NoData";
import { TwButton, TwSelect, TwSortDropdown } from "../shared/tw";
// Constants and helper functions
import { route } from "../../common/constant/route";
import { paginationPerpage, tagType } from "../../common/constant/common";
import {
  objToReactSelect,
  reactSelectobjToArray,
} from "../../common/helpers/helper";

/**
 * Campaign component displays a list of campaigns with sorting and filtering options.
 *
 * @component
 * @example
 * return <Campaign />;
 *
 * This component fetches and displays campaigns from the API. It provides options
 * to sort and filter campaigns based on name and tags. It also includes pagination
 * to navigate through multiple pages of campaigns. The component uses the `useCampaignFetchQuery`
 * and `useTagFetchQuery` hooks to fetch data, and manages state for sorting, filtering, and pagination.
 *
 * It conditionally shows the filter section and handles user interactions to update the filter,
 * apply sorting, and navigate to different pages. Additionally, it renders a message if no data
 * is available and allows users to create a new campaign.
 *
 * @returns {JSX.Element} The rendered Campaign component.
 */
const Campaign: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [filters, setFilters] = useState({
    name: "",
    tags: [],
  });
  const defaultSorting = {
    column: t("defaultSorting.column"),
    type: t("defaultSorting.type"),
  };
  const [sortBy, setSortBy] = useState(defaultSorting);

  const { data, refetchCampaigns }: any = useCampaignFetchQuery({
    page: currentPage,
    per_page: paginationPerpage,
    sort_by: sortBy.column.toLowerCase(),
    sort_type: sortBy.type.toLowerCase(),
    filter_name: filters.name,
    filter_tags: reactSelectobjToArray(filters.tags),
  });

  const { tags }: any = useTagFetchQuery(tagType.campaign);
  /**
   * Handles pagination changes.
   * @param {number} page - The new page number.
   */
  const handlePagination = (page: number) => {
    setCurrentPage(page);
  };
  /**
   * Handles sorting changes.
   * @param {string} column - The column to sort by.
   * @param {string} type - The sort type (e.g., ascending or descending).
   */
  const handleClickSortBy = (column: string, type: string) => {
    setSortBy({
      column,
      type,
    });
  };
  /**
   * Toggles the visibility of the filter section.
   * Resets filters if closing the filter section.
   */
  const toggleFilterVisibility = () => {
    if (isFilterVisible) {
      setFilters({
        name: "",
        tags: [],
      });
    }
    setIsFilterVisible(!isFilterVisible);
  };
  /**
   * Navigates to the create campaign page.
   */
  const handleCreateNew = () => {
    navigate(route.campaign.create);
  };
  /**
   * Applies the current filters and refetches campaigns.
   */
  const handleClickApplyFilter = () => {
    if (filters.name || filters.tags.length) refetchCampaigns();
  };
  /**
   * Handles changes in selected tags.
   * @param {any} value - The new selected tags.
   */
  const handleTagChange = (value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: value,
    }));
  };
  /**
   * Handles changes in the name filter input.
   * @param {any} e - The event object.
   */
  const handleNameChange = (e: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      name: e.target.value,
    }));
  };
  // Refetch campaigns if filters change and are cleared
  useEffect(() => {
    if (filters.name === "" && filters.tags.length === 0) {
      refetchCampaigns();
    }
  }, [filters, refetchCampaigns]);

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
          {(data && data.campaigns.length) || isFilterVisible ? (
            <div className="col-span-1 md:col-span-2">
              <div className="bg-white shadow-md rounded-lg px-7 py-3">
                <div className="flex justify-between items-center">
                  <div className="flex-grow">
                    <TwSortDropdown
                      sortBy={sortBy}
                      onClickSortBy={handleClickSortBy}
                    />
                  </div>
                  <Button
                    color="gray"
                    className="border-none"
                    onClick={toggleFilterVisibility}
                  >
                    <IoFilterSharp className="mr-2 h-5 w-5" />
                    {t("campaigns.filters.text", {
                      type: isFilterVisible ? "Close" : "Add",
                    })}
                  </Button>
                </div>
                <div
                  className={`mt-2 transition-opacity duration-500 ${
                    isFilterVisible ? "opacity-100" : "opacity-0 hidden"
                  }`}
                >
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-8">
                    <div className="col-span-1 md:col-span-2">
                      <label
                        htmlFor="searchByUrl"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("campaigns.filters.name")}
                      </label>
                      <input
                        onChange={handleNameChange}
                        value={filters.name}
                        type="text"
                        name="searchByUrl"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    {tags && (
                      <div className="col-span-1 md:col-span-2">
                        <label
                          htmlFor="tags"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("campaigns.filters.tags")}
                        </label>
                        <TwSelect
                          onChange={handleTagChange}
                          value={filters.tags}
                          name="tags"
                          isMulti={true}
                          className="w-full mt-1"
                          options={objToReactSelect(tags)}
                        />
                      </div>
                    )}
                    <div
                      className={`col-span-1 flex items-center justify-end ${
                        tags ? "md:col-span-4" : "md:col-span-6"
                      }`}
                    >
                      <TwButton
                        title={t("campaigns.filters.button.applyFilter")}
                        className="w-full md:w-auto mt-5 hover:cursor-pointer"
                        onClick={handleClickApplyFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {data && data.campaigns.length === 0 && (
            <NoData
              buttonClass="hidden md:block"
              buttonShow={!isFilterVisible}
              title={t("campaigns.noData.text")}
              buttonTitle={t("campaigns.noData.button.createCampaign")}
              onClick={handleCreateNew}
            />
          )}
          {data &&
            data.campaigns.map((campaign: any) => (
              <CampaignCard
                key={campaign.campaign_key}
                title={campaign.name}
                description={campaign.description}
                campaignKey={campaign.campaign_key}
                createdAt={campaign.created_at}
                tags={campaign.tags}
                urlCount={campaign.shortUrls.length}
              />
            ))}

          {data && data.totalPages > 1 && (
            <div className="col-span-1 md:col-span-2">
              <div className="bg-white shadow-md rounded-lg px-7 py-3">
                <div className="flex flex-col md:flex-row justify-between md:justify-center items-start md:items-center">
                  <Paginations
                    onChange={handlePagination}
                    totalPages={data.totalPages}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* New button for mobile view */}
      <Button
        className="md:hidden fixed bottom-4 right-4 bg-blue-500 text-white rounded-full h-16 w-16 flex items-center justify-center shadow-lg"
        onClick={handleCreateNew}
      >
        <FaPlus className="h-5 w-5" />
      </Button>
    </div>
  );
};

export default Campaign;

// Application-specific imports
import { truncateText } from "../../../common/helpers/helper";
import { ICustomLinkProps } from "../../../common/interfaces/IForm";

/**
 * TwLink component renders a stylized link with customizable color and additional styling.
 *
 * This component is used to display a clickable link (`<a>` tag) with a specified URL and
 * color. The link will open in a new tab and apply underline styling on hover.
 *
 * @param {ICustomLinkProps} props - The properties for the TwLink component.
 * @param {string} props.url - The URL to which the link points. This is displayed as the link text.
 * @param {string} [props.color="blue"] - The color of the link text. Can be "blue" or "gray". Defaults to "blue".
 * @param {string} [props.className=""] - Additional CSS class names to apply to the component.
 * @param {number} [props.characterLimit] - Limit the showing charcters in the link
 *
 * @returns {JSX.Element} The rendered TwLink component.
 *
 * @example
 * <TwLink url="https://example.com" color="blue" className="my-custom-class" />
 */
export const TwLink: React.FC<ICustomLinkProps> = ({
  url,
  color,
  className = "",
  characterLimit,
}) => (
  <h2
    className={`text-1xl hover:underline font-semibold mb-2 md:mb-0 ${
      color === "blue" ? "text-blue-400" : "text-gray-700"
    } ${className}`}
  >
    <a href={url} target="_blank" rel="noopener noreferrer">
      {characterLimit ? truncateText(url, characterLimit) : url}
    </a>
  </h2>
);

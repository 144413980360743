// React and external library imports
import React, { useEffect, useState } from "react";
import { Button } from "flowbite-react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//Icons
import { IoFilterSharp } from "react-icons/io5";
import { FaPlus } from "react-icons/fa";
// Application-specific imports
import ShortUrlCard from "./ShortUrlCard";
import { route } from "../../common/constant/route";
import { useShortUrlFetchQuery } from "../../queries/useShortUrlQuery";
import { Paginations } from "../shared/Paginations";
import { paginationPerpage, tagType } from "../../common/constant/common";
import { NoData } from "../shared/NoData";
import { TwButton } from "../shared/tw/TwButton";
import { TwSelect, TwSortDropdown } from "../shared/tw";
import { useTagFetchQuery } from "../../queries/useTagQuery";
import {
  objToReactSelect,
  reactSelectobjToArray,
} from "../../common/helpers/helper";

/**
 * ShortUrls component displays a list of short URLs for a specific campaign.
 * It provides functionality to filter, sort, and paginate the list of URLs.
 * The component uses `react-hook-form` for managing form state, Flowbite components for UI elements,
 * and custom hooks for data fetching and manipulation.
 *
 * @component
 * @example
 * // Example usage:
 * <ShortUrls />
 *
 * @returns {JSX.Element} The rendered list of short URLs with filtering, sorting, and pagination options.
 */
const ShortUrls: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id: campaignKey }: any = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filters, setFilters] = useState({
    query: "",
    tags: [],
  });

  const defaultSorting = {
    column: t("defaultSorting.column"),
    type: t("defaultSorting.type"),
  };

  const [sortBy, setSortBy] = useState(defaultSorting);

  // Fetch short URLs with applied filters and sorting
  const { data, refetchShortUrls }: any = useShortUrlFetchQuery(campaignKey, {
    sort_by: sortBy.column.toLowerCase(),
    sort_type: sortBy.type.toLowerCase(),
    page: currentPage,
    per_page: paginationPerpage,
    filter_query: filters.query,
    filter_tags: reactSelectobjToArray(filters.tags),
  });
  const { tags }: any = useTagFetchQuery(tagType.shorturl);

  // Toggle filter visibility and reset filters if closing
  const toggleFilterVisibility = () => {
    if (isFilterVisible) {
      setFilters({
        query: "",
        tags: [],
      });
    }
    setIsFilterVisible(!isFilterVisible);
  };

  // Navigate to the create short URL page
  const handleCreateShortUrl = () => {
    navigate(route.link.createId(campaignKey));
  };

  // Update the current page for pagination
  const handlePagination = (page: number) => {
    setCurrentPage(page);
  };

  // Update sorting options
  const handleClickSortBy = (column: string, type: string) => {
    setSortBy({
      column,
      type,
    });
  };

  // Refetch short URLs when filters are applied
  const handleClickApplyFilter = () => {
    if (filters.query || filters.tags.length) refetchShortUrls();
  };

  // Update filter tags
  const handleTagChange = (value: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: value,
    }));
  };

  // Update filter query
  const handleNameChange = (e: any) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      query: e.target.value,
    }));
  };

  // Refetch short URLs if filters change
  useEffect(() => {
    if (filters.query === "" && filters.tags.length === 0) {
      refetchShortUrls();
    }
  }, [filters, refetchShortUrls]);

  // Refetch short URLs if sorting options change
  useEffect(() => {
    if (
      defaultSorting.column !== sortBy.column &&
      defaultSorting.type !== sortBy.type
    )
      refetchShortUrls();
  }, [sortBy, refetchShortUrls]);

  return (
    <div className="bg-gray-100 min-h-screen p-4">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-2">
          {(data && data.urls.length !== 0) || isFilterVisible ? (
            <div className="col-span-1 md:col-span-2">
              <div className="bg-white shadow-md rounded-lg px-7 py-3">
                <div className="flex justify-between items-center">
                  <div className="mb-4 md:mb-0 md:flex-grow">
                    <TwSortDropdown
                      sortBy={sortBy}
                      onClickSortBy={handleClickSortBy}
                    />
                  </div>
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <Button
                      color="gray"
                      className="border-none mb-4 md:mb-0"
                      onClick={toggleFilterVisibility}
                    >
                      <IoFilterSharp className="mr-2 h-5 w-5" />
                      {t("shortUrls.filters.text", {
                        type: isFilterVisible ? "Close" : "Add",
                      })}
                    </Button>
                    <div className="w-full md:w-auto hidden md:block">
                      <TwButton
                        title={t("shortUrls.button.createShortUrl")}
                        color="blue"
                        className="border-none w-full md:w-auto"
                        onClick={handleCreateShortUrl}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`mt-4 transition-opacity duration-500 ${
                    isFilterVisible ? "opacity-100" : "opacity-0 hidden"
                  }`}
                >
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-8">
                    <div className="col-span-1 md:col-span-2">
                      <label
                        htmlFor="searchQuery"
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("shortUrls.filters.searchQuery")}
                      </label>
                      <input
                        onChange={handleNameChange}
                        value={filters.query}
                        type="text"
                        name="searchByUrl"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                    {tags && (
                      <div className="col-span-1 md:col-span-2">
                        <label
                          htmlFor="tags"
                          className="block text-sm font-medium text-gray-700"
                        >
                          {t("shortUrls.filters.tags")}
                        </label>
                        <TwSelect
                          onChange={handleTagChange}
                          value={filters.tags}
                          name="tags"
                          isMulti={true}
                          className="w-full mt-1"
                          options={objToReactSelect(tags)}
                        />
                      </div>
                    )}

                    <div
                      className={`col-span-1 flex items-center justify-end ${
                        tags ? "md:col-span-4" : "md:col-span-6"
                      }`}
                    >
                      <TwButton
                        title={t("shortUrls.filters.button.applyFilter")}
                        className={`w-full md:w-auto mt-5 hover:cursor-pointer`}
                        onClick={handleClickApplyFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {data && data.urls.length === 0 && (
            <NoData
              buttonClass="hidden md:block"
              buttonShow={!isFilterVisible}
              title={t("shortUrls.noData.text")}
              buttonTitle={t("shortUrls.button.createShortUrl")}
              onClick={handleCreateShortUrl}
            />
          )}
          {data &&
            data.urls.map((url: any) => (
              <ShortUrlCard
                key={`${campaignKey}-${url.id}`}
                url={url}
                campaignKey={campaignKey}
              />
            ))}
          {data && data.totalPages > 1 && (
            <div className="col-span-1 md:col-span-2">
              <div className="bg-white shadow-md rounded-lg px-7 py-3">
                <div className="flex flex-col md:flex-row justify-between md:justify-center items-start md:items-center">
                  <Paginations
                    onChange={handlePagination}
                    totalPages={data.totalPages}
                    currentPage={currentPage}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* New button for mobile view */}
      <Button
        className="md:hidden fixed bottom-4 right-4 bg-blue-500 text-white rounded-full h-16 w-16 flex items-center justify-center shadow-lg"
        onClick={handleCreateShortUrl}
      >
        <FaPlus className="h-5 w-5" />
      </Button>
    </div>
  );
};

export default ShortUrls;

// React and external library imports
import { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { IoIosArrowDown } from "react-icons/io";
import { useTranslation } from "react-i18next";

/**
 * Header component displays the navigation bar with user authentication options.
 *
 * This component includes:
 * - A menu button for mobile view.
 * - A user profile dropdown that shows user information when authenticated.
 * - Authentication options like "Log In" and "Log Out".
 *
 * It manages the user dropdown state and handles outside clicks to close the dropdown.
 *
 * @component
 * @example
 * return <Header onMenuClick={handleMenuClick} />;
 *
 * @param {Object} props - The component's props.
 * @param {Function} props.onMenuClick - Callback function triggered when the menu button is clicked.
 * @returns {JSX.Element} The rendered Header component.
 */
export const Header = ({ onMenuClick }: any) => {
  const { t } = useTranslation();
  const userRef = useRef<HTMLDivElement>(null);
  const { loginWithRedirect, logout, user, isAuthenticated, isLoading } =
    useAuth0();
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  /**
   * Toggles the visibility of the user dropdown menu.
   */
  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
  };
  /**
   * Closes the user dropdown menu if a click occurs outside of the dropdown.
   *
   * @param {Event} event - The click event.
   */
  const handleClickOutside = (event: any) => {
    if (userRef.current && !userRef.current.contains(event.target)) {
      setIsUserDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="flex items-center justify-between lg:justify-end gap-3 px-4 bg-white border-b border-gray-200 h-14 top-nav">
      <div className="lg:hidden">
        <button
          onClick={onMenuClick}
          className="p-2 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
      {/* User Profile Dropdown */}
      <div className="relative inline-block text-left z-10" ref={userRef}>
        {!isLoading && isAuthenticated && (
          <button
            type="button"
            className="inline-flex justify-center p-2 rounded-md dropdownButton hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            aria-expanded={isUserDropdownOpen}
            aria-haspopup="true"
            onClick={toggleUserDropdown}
          >
            {!isLoading && isAuthenticated && (
              <div className="flex items-center gap-2">
                <div className="flex items-center justify-center w-8 h-8 text-white rounded-full bg-gray-950 user-profile">
                  {user?.name?.charAt(0)}
                </div>
                <span>{user?.name}</span>
                <IoIosArrowDown />
              </div>
            )}
            {isLoading && <div>Loading...</div>}
            {!isLoading && !isAuthenticated && (
              <button>{t("header.button.signIn")}</button>
            )}
          </button>
        )}
        {/* {!isLoading && !isAuthenticated && (
          <TwButton
            title={t("header.button.signIn")}
            className="px-4 py-2 text-black font-semibold rounded-md hover:bg-black  hover:text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            aria-expanded={isUserDropdownOpen}
            aria-haspopup="true"
            onClick={() => loginWithRedirect()}
          />
        )} */}
        {/* Dropdown Menu Items */}
        {isUserDropdownOpen && (
          <div className="absolute right-0 mt-2 origin-top-right bg-white rounded-md shadow-lg w-80 dropdownMenu ring-1 ring-black ring-opacity-5">
            <div className="flex items-center gap-2 px-4 py-2 border-b border-b-gray-200">
              {!isLoading && isAuthenticated && (
                <div className="flex items-center justify-center w-10 h-10 text-white rounded-full bg-gray-950 user-profile">
                  {user?.name?.charAt(0)}
                </div>
              )}
              <div className="text-sm text-gray-700 right">
                <p>{!isLoading && isAuthenticated && user?.name}</p>
                <p>{!isLoading && isAuthenticated && user?.email}</p>
              </div>
            </div>
            <div className="py-1" role="menu" aria-orientation="vertical">
              <a
                href="/"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                role="menuitem"
              >
                {t("header.dropdown.support")}
              </a>
              {!isAuthenticated && (
                <button
                  type="submit"
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  onClick={() => loginWithRedirect()}
                >
                  {t("header.button.signIn")}
                </button>
              )}
              {isAuthenticated && (
                <button
                  type="submit"
                  className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  {t("header.button.signOut")}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

// React and external library imports
import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Icons and resources
import {
  HiOutlineHome,
  HiPresentationChartLine,
  HiOutlinePlus,
} from "react-icons/hi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import logo from "../../../assets/images/logo.png";
import { Dialog, DialogPanel } from "@headlessui/react";
import { route } from "../../../common/constant/route";
import { TwButton } from "../../shared/tw/TwButton";

/**
 * LeftNavBar component provides a sidebar navigation for desktop and mobile views.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.isOpen - Flag to control the modal's visibility.
 * @param {Function} props.onClose - Function to close the modal.
 * @returns {JSX.Element} The rendered LeftNavBar component.
 */
export const LeftNavBar = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const activePath = location.pathname;

  /**
   * Toggles the sidebar collapse state.
   */
  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };
  /**
   * Navigates to the create campaign page and closes the modal.
   */
  const handleCreateNew = () => {
    onClose();
    navigate(route.campaign.create);
  };

  return (
    <>
      {/* Sidebar for laptop and larger screens */}
      <div
        className={`relative p-3 duration-300 ease-in-out bg-white border-r border-gray-200 ${
          isCollapsed ? "w-20" : "w-60"
        } left-column hidden lg:block`}
      >
        <img className="mb-6" src={logo} alt="Logo" width={50} />
        <div className="pb-4 border-b border-gray-200 create-new-btn-wrp">
          <TwButton
            className={`w-full ${isCollapsed && "px-0 pl-4"}`}
            onClick={handleCreateNew}
            type="button"
            title={
              !isCollapsed ? (
                t("leftNavBar.createCampaign.text")
              ) : (
                <HiOutlinePlus className="h-5 w-5" />
              )
            }
          />
        </div>
        <ul className="relative mt-4">
          <li className="mb-3 menu-item">
            <Link
              to={route.dashboard}
              className={`flex items-center h-10 gap-3 px-3 py-2 nav-link hover:bg-gray-100 hover:rounded-md focus:outline-none ${
                activePath === route.dashboard ? "bg-gray-100 rounded-md" : ""
              }`}
            >
              <div
                className={`mr-2 ${
                  activePath === route.dashboard ? "active-indicator" : "hidden"
                }`}
              ></div>
              <HiOutlineHome className="mr-2 h-5 w-5" />
              {!isCollapsed && (
                <span className="font-medium">
                  {t("leftNavBar.menuItem.dashboard")}
                </span>
              )}
            </Link>
          </li>
          <li className="mb-3 menu-item">
            <Link
              to={route.campaign.lists}
              className={`flex items-center h-10 gap-3 px-3 py-2 nav-link hover:bg-gray-100 hover:rounded-md focus:outline-none ${
                activePath === route.campaign.lists
                  ? "bg-gray-100 rounded-md"
                  : ""
              }`}
            >
              <div
                className={`mr-2 ${
                  activePath === route.campaign.lists
                    ? "active-indicator"
                    : "hidden"
                }`}
              ></div>
              <HiPresentationChartLine className="mr-2 h-5 w-5" />
              {!isCollapsed && (
                <span className="font-medium">
                  {t("leftNavBar.menuItem.campaigns")}
                </span>
              )}
            </Link>
          </li>
        </ul>
        <button
          onClick={handleToggle}
          className="absolute flex items-center justify-center w-8 h-8 bg-white border border-gray-100 rounded-full shadow-md expand-btn top-8 -right-4"
        >
          {isCollapsed ? (
            <IoIosArrowBack className="mr-2 h-5 w-5" />
          ) : (
            <IoIosArrowForward />
          )}
        </button>
      </div>

      {/* Sidebar for mobile and tablet screens */}
      <Dialog
        open={isOpen}
        onClose={onClose}
        className="relative z-50 transition duration-300 ease-out data-[closed]:opacity-0"
        transition
      >
        <div className="fixed inset-0 bg-black bg-opacity-30"></div>

        <div className="fixed inset-0 flex items-start">
          <DialogPanel className="w-full max-w-xs p-6 bg-white h-full">
            <div className="flex items-center justify-between mb-4">
              <img className="mb-6" src={logo} alt="Logo" width={50} />
              <button
                className="text-gray-500 hover:text-gray-900"
                onClick={onClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="pb-4 border-b border-gray-200 create-new-btn-wrp">
              <TwButton
                className={`w-full mb-4 ${isCollapsed && "px-0 pl-4"}`}
                onClick={handleCreateNew}
                type="button"
                title={
                  !isCollapsed ? (
                    t("leftNavBar.createCampaign.text")
                  ) : (
                    <HiOutlinePlus className="h-5 w-5" />
                  )
                }
              />
            </div>
            <ul>
              <li className="mb-3">
                <Link
                  to={route.home}
                  className={`flex items-center h-10 gap-3 px-3 py-2 nav-link hover:bg-gray-100 hover:rounded-md focus:outline-none ${
                    activePath === route.home ? "bg-gray-100 rounded-md" : ""
                  }`}
                  onClick={onClose}
                >
                  <HiOutlineHome className="mr-2 h-5 w-5" />
                  <span className="font-medium">
                    {t("leftNavBar.menuItem.dashboard")}
                  </span>
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  to={route.campaign.lists}
                  className={`flex items-center h-10 gap-3 px-3 py-2 nav-link hover:bg-gray-100 hover:rounded-md focus:outline-none ${
                    activePath === route.campaign.lists
                      ? "bg-gray-100 rounded-md"
                      : ""
                  }`}
                  onClick={onClose}
                >
                  <HiPresentationChartLine className="mr-2 h-5 w-5" />
                  <span className="font-medium">
                    {t("leftNavBar.menuItem.campaigns")}
                  </span>
                </Link>
              </li>
            </ul>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

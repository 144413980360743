// React and external library imports
import React from "react";
import Select from "react-select";
// Application-specific imports
import { ITwSelect } from "../../../common/interfaces/IForm";

/**
 * Renders a customizable dropdown select input using `react-select`.
 *
 * Allows single or multiple selections with options for placeholder text, default values, and styling.
 *
 * @param {ITwSelect} props - Component properties.
 * @param {string} props.name - Name of the select input.
 * @param {string} [props.className=""] - Optional CSS class names.
 * @param {boolean} [props.isMulti=false] - Allows multiple selections if `true`.
 * @param {Array} [props.defaultValue=[]] - Default value(s) on initial render.
 * @param {Array} [props.options=[]] - Dropdown options in `{ value: string, label: string }` format.
 * @param {string} [props.placeholder=""] - Placeholder text.
 * @param {object} [props.value={}] - Currently selected value(s).
 * @param {(selected: any) => void} [props.onChange] - Callback for selection changes.
 *
 * @returns {JSX.Element} The rendered `TwSelect` component.
 *
 * @example
 * <TwSelect
 *   name="example"
 *   isMulti
 *   defaultValue={[{ value: 'option1', label: 'Option 1' }]}
 *   options={[{ value: 'option1', label: 'Option 1' }, { value: 'option2', label: 'Option 2' }]}
 *   placeholder="Select an option"
 *   onChange={(selected) => console.log(selected)}
 * />
 */
export const TwSelect: React.FC<ITwSelect> = ({
  name,
  className,
  isMulti = false,
  defaultValue = [],
  options = [],
  placeholder = "",
  value = {},
  onChange,
}) => (
  <div className="flex-shrink-0 flex">
    <Select
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      defaultValue={defaultValue}
      options={options}
      isMulti={isMulti}
      name={name}
      className={`basic-multi-select ${className}`}
      classNamePrefix="select"
    />
  </div>
);

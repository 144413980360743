//External library imports
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
// Application-specific imports
import apiService from "../services/api/apiService";
import { api } from "../common/constant/api";
import { ICampaign } from "../common/interfaces/IForm";
import { IApiResponse } from "../common/interfaces/IResponse";
import { objToQueryParams } from "../common/helpers/helper";

/**
 * Fetches campaigns based on the provided parameters.
 * @param {object} [params={}] - The query parameters for fetching campaigns.
 * @returns {Promise<any>} - The fetched campaign data.
 */
const fetchCampaign = async (params: object = {}): Promise<any> => {
  const response = await apiService.get(
    api.campaign.get(objToQueryParams(params))
  );
  if (response.data) {
    return response.data;
  } else {
    return [];
  }
};

/**
 * Creates a new campaign.
 * @param {ICampaign} newCampaignData - The data for the new campaign.
 * @returns {Promise<boolean>} - The response from the API.
 */
const createCampaign = async (newCampaignData: ICampaign): Promise<boolean> => {
  const response: any = await apiService.post(
    api.campaign.create,
    newCampaignData
  );
  return response.data;
};

/**
 * Updates an existing campaign.
 * @param {object} params - The update parameters.
 * @param {ICampaign} params.updateCampaignData - The data to update the campaign.
 * @param {string} params.campaignId - The ID of the campaign to update.
 * @returns {Promise<any>} - The response from the API.
 */
const updateCampaign = async ({
  updateCampaignData,
  campaignId,
}: {
  updateCampaignData: ICampaign;
  campaignId: string;
}): Promise<any> => {
  const response: any = await apiService.put(
    api.campaign.update(campaignId),
    updateCampaignData
  );
  return response.data;
};

/**
 * Deletes a campaign.
 * @param {string} campaignKey - The key of the campaign to delete.
 * @returns {Promise<void>} - The response from the API.
 */
const deleteCampaign = async (campaignKey: string): Promise<void> => {
  const response: any = await apiService.delete(
    api.campaign.delete(campaignKey)
  );
  return response.data;
};

/**
 * Custom hook to fetch campaigns with query parameters.
 * @param {object} [params={}] - The query parameters for fetching campaigns.
 * @returns {object} - The query result including data, loading, error, and refetch functions.
 */
export const useCampaignFetchQuery = (params: any = {}) => {
  let result = null;

  let queryKey: any = ["campaigns"];

  if ("id" in params) {
    queryKey.push(params.id);
  } else {
    queryKey = [
      ...queryKey,
      ...[params.page, params.per_page, params.sort_by, params.sort_type],
    ];
  }

  const { data, isLoading, isError, refetch }: UseQueryResult<ICampaign> =
    useQuery({
      queryKey: queryKey,
      queryFn: () => fetchCampaign(params),
    });

  if (data && "data" in data) {
    result = data.data;
  }

  return {
    data: result,
    isLoading,
    isError,
    refetchCampaigns: refetch,
  };
};

/**
 * Custom hook to handle campaign mutations (create, update, delete).
 * @returns {object} - The mutation handlers and statuses.
 */
export const useCampaignMutateQuery = () => {
  let apiErrorMessage,
    apiSuccessMessage = null;
  let generalError = false;
  const queryClient = useQueryClient();

  //create campaign
  const {
    data: createResponse,
    mutate: mutateCampaignUser,
    isPending: isCreating,
    isError: isCreateError,
    isSuccess,
    error: createError,
  } = useMutation({
    mutationFn: createCampaign,
  });

  //delete campaign
  const {
    data: deleteResponse,
    isSuccess: isDeleted,
    mutate: mutateDeleteCampaign,
  } = useMutation({
    mutationFn: deleteCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns"] });
    },
  });

  //update campaign
  const {
    data: updateResponse,
    isSuccess: isUpdated,
    isPending: isUpdating,
    mutate: mutateUpdateCampaign,
    isError: isUpdateError,
    error: updateError,
  } = useMutation({
    mutationFn: updateCampaign,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaigns"] });
    },
  });

  /**
   * Handles creating a new campaign.
   * @param {ICampaign} newCampaignData - The data for the new campaign.
   */
  const createCampignHandler = async (newCampaignData: ICampaign) => {
    await mutateCampaignUser(newCampaignData);
  };

  /**
   * Handles deleting a campaign.
   * @param {string} campaignKey - The key of the campaign to delete.
   */
  const deleteCampaignHandler = async (campaignKey: string) => {
    await mutateDeleteCampaign(campaignKey);
  };

  /**
   * Handles updating a campaign.
   * @param {string} campaignId - The ID of the campaign to update.
   * @param {ICampaign} updateCampaignData - The data to update the campaign.
   */
  const updateCampaignHandler = async (
    campaignId: string,
    updateCampaignData: ICampaign
  ) => {
    await mutateUpdateCampaign({ updateCampaignData, campaignId });
  };

  if (isSuccess || isDeleted || isUpdated) {
    const response = isSuccess
      ? createResponse
      : isDeleted
      ? deleteResponse
      : isUpdated
      ? updateResponse
      : null;
    if (response) {
      const apiData = response as unknown as IApiResponse;
      apiSuccessMessage = apiData.message;
    }
  }

  if (createError || updateError) {
    const error = createError ?? updateError;
    const apiError = error as any;

    if (apiError.response.status === 409) {
      apiErrorMessage = apiError.response.data.error;
    } else if (apiError.response.status === 500) {
      generalError = true;
    }
  }

  return {
    isDeleted,
    isSuccess,
    isCreating,
    isUpdated,
    isUpdating,
    apiSuccessMessage,
    apiError: apiErrorMessage,
    isApiError: isCreateError || isUpdateError,
    isGeneralError: generalError,
    createCampaign: createCampignHandler,
    deleteCampaign: deleteCampaignHandler,
    updateCampaign: updateCampaignHandler,
  };
};

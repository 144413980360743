// React and external library imports
import React, { useState } from "react";
import { Card, Table } from "flowbite-react";

// Chart Components
import { Line, Bar, Doughnut, Radar } from "react-chartjs-2";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ScatterController,
  RadarController,
  RadialLinearScale,
  Filler,
} from "chart.js";

// Custom Components
import { TwBadge, TwTitle } from "../shared/tw";
import Select from "react-select";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  ScatterController,
  RadarController,
  RadialLinearScale,
  Filler
);

const dataSet: any = {
  empower_future_leaders: {
    chart3: {
      label: "Empower Future Leaders",
      data: [120, 173, 54, 437, 34, 343, 225, 222, 111, 444],
    },
    chart4: {
      label: "Empower Future Leaders",
      data: [65, 59, 90, 81, 56, 53, 35],
    },
    chart5: {
      label: "Empower Future Leaders",
      data: [65, 59, 90, 81, 56],
    },
  },
  vet_marketing: {
    chart3: {
      label: "Vet Marketing",
      data: [44, 23, 244, 345, 223, 42, 534, 22, 333, 888],
    },
    chart4: {
      label: "Vet Marketing",
      data: [10, 20, 90, 30, 56, 44, 22],
    },
    chart5: {
      label: "Vet Marketing",
      data: [10, 20, 90, 30, 56],
    },
  },
  bright_horizons_campaign: {
    chart3: {
      label: "Bright Horizons Campaign",
      data: [587, 173, 903, 47, 668, 349, 225, 722, 446, 44],
    },
    chart4: {
      label: "Bright Horizons Campaign",
      data: [587, 173, 903, 47, 668, 349, 225],
    },
    chart5: {
      label: "Bright Horizons Campaign",
      data: [40, 10, 20, 5, 80],
    },
  },
};

const chart1 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Empower Future Leaders",
      backgroundColor: "rgba(63,131,248,1)",
      borderColor: "rgba(63,131,248,1)",
      data: [165, 432, 44, 481, 1000, 525, 800],
      tension: 0.3,
      pointRadius: 4,
      pointHoverBorderWidth: 10,
      // pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    {
      label: "Green Tomorrow Initiative",
      backgroundColor: "rgba(245, 66, 105,1)",
      borderColor: "rgba(245, 66, 105,1)",
      data: [10, 23, 80, 200, 500, 60, 430],
      tension: 0.3,
      pointRadius: 4,
      pointHoverBorderWidth: 10,
      // pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    {
      label: "Bright Horizons Campaign",
      backgroundColor: "rgba(245, 215, 66)",
      borderColor: "rgba(245, 215, 66)",
      data: [400, 300, 600, 481, 690, 456, 34],
      tension: 0.3,
      pointRadius: 4,
      pointHoverBorderWidth: 10,
      // pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  ],
};

const chart2 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "VET Marketing",
      backgroundColor: "rgba(54, 168, 50,1)",
      borderColor: "rgba(54, 168, 50,1)",
      data: [34, 70, 100, 244, 333, 111, 800],
      tension: 0.3,
      pointRadius: 4,
      pointHoverBorderWidth: 10,
      // pointBackgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  ],
};

const chart3 = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: dataSet.empower_future_leaders.chart3.label,
      backgroundColor: "rgba(245, 66, 105,1)",
      borderColor: "rgba(245, 66, 105,1)",
      data: dataSet.empower_future_leaders.chart3.data,
      tension: 0.3,
      pointRadius: 4,
      pointHoverBorderWidth: 10,
    },
  ],
};

const chart4 = {
  labels: [
    "Linkedin",
    "Facebook",
    "Google",
    "Twitter",
    "Xurl",
    "Direct",
    "Other",
  ],
  datasets: [
    {
      label: dataSet.empower_future_leaders.chart4.label,
      data: dataSet.empower_future_leaders.chart4.data,
      backgroundColor: "rgba(245, 215, 66, 0.2)",
      borderColor: "rgba(245, 215, 66, 1)",
      pointBackgroundColor: "rgba(245, 215, 66, 1)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgba(245, 215, 66, 1)",
    },
  ],
};

const chart5 = {
  labels: ["Laptop", "Mobile", "Tablet", "E-Reader", "Other"],
  datasets: [
    {
      label: dataSet.empower_future_leaders.chart5.label,
      data: dataSet.empower_future_leaders.chart5.data,
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

/**
 * Dashboard component displays various charts and data tables related to campaign performance.
 *
 * @component
 * @example
 * return <Dashboard />;
 *
 * This component includes:
 * - A line chart for top-performing campaigns.
 * - A line chart for recently added campaigns.
 * - Filters for selecting different campaigns.
 * - A bar chart for clicks over time.
 * - A radar chart for clicks by referrer.
 * - A doughnut chart for clicks by device.
 * - A table displaying clicks by location.
 *
 * It manages chart data updates based on selected filters and displays various charts using `react-chartjs-2`.
 *
 * @returns {JSX.Element} The rendered Dashboard component.
 */
const Dashboard: React.FC = () => {
  const [chart3Data, setChart3Data] = useState<any>(chart3);
  const [chart5Data, setChart5Data] = useState<any>(chart5);

  const [filter, setFilter] = useState<any>({
    label: "Empower Future Leaders",
    value: "empower_future_leaders",
  });

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  /**
   * Handles filter change and updates chart data accordingly.
   *
   * @param {any} value The selected filter value.
   */
  const handelOnchage = (value: any) => {
    setFilter(value);

    const selectedValue = value.value;
    const selectedLabel = value.label;

    // Update chart3Data
    const updatedChart3Data = {
      ...chart3Data,
      datasets: [
        {
          ...chart3Data.datasets[0],
          label: selectedLabel,
          data: dataSet[selectedValue].chart3.data,
        },
      ],
    };
    setChart3Data(updatedChart3Data);

    // Update chart4Data
    const updatedChart5Data = {
      ...chart5Data,
      datasets: [
        {
          ...chart5Data.datasets[0],
          label: selectedLabel,
          data: dataSet[selectedValue].chart4.data, // Ensure dataSet[selectedValue].chart4.data exists
        },
      ],
    };
    setChart5Data(updatedChart5Data);
  };

  return (
    <div>
      <span className="hidden lg:block">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div>
            <Card className="px-4">
              <TwTitle title="Top Performing Campaigns" />
              <Line data={chart1} options={options} />
            </Card>
          </div>
          <div>
            <Card className="px-4">
              <TwTitle title="Recently added Campaigns" />
              <Line data={chart2} options={options} />
            </Card>
          </div>
        </div>
      </span>

      <div className="col-span-1 md:col-span-2 p-5 pt-0 md:pt-5">
        <div className="bg-white shadow-md rounded-lg px-5 py-2">
          <div className="flex justify-between items-center">
            <h1>Filters</h1>
            <Select
              defaultValue={filter}
              onChange={handelOnchage}
              isSearchable={false}
              placeholder={"Select Camapign"}
              className="w-60"
              options={[
                {
                  label: "Empower Future Leaders",
                  value: "empower_future_leaders",
                },
                {
                  label: "VET Marketing",
                  value: "vet_marketing",
                },
                {
                  label: "Bright Horizons Campaign",
                  value: "bright_horizons_campaign",
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
        <div className="flex flex-col gap-y-4">
          {chart3Data && (
            <div>
              <Card className="px-4">
                <TwTitle title="Clicks over Time" />
                <Bar data={chart3Data} options={options} />
              </Card>
            </div>
          )}
          <div>
            <Card className="px-4">
              <TwTitle title="Clicks by Referrer" />
              <Radar data={chart4} options={options} />
            </Card>
          </div>
        </div>

        <div className="flex flex-col gap-y-4">
          {chart5Data && (
            <div>
              <Card className="px-4">
                <TwTitle title="Clicks by Device" />
                <Doughnut data={chart5Data} options={options} />
              </Card>
            </div>
          )}

          <div>
            <Card className="px-4">
              <TwTitle title="Clicks by Location" />
              <div className="overflow-x-auto">
                <Table>
                  <Table.Head>
                    <Table.HeadCell>#</Table.HeadCell>
                    <Table.HeadCell>Country</Table.HeadCell>
                    <Table.HeadCell>Clicks</Table.HeadCell>
                    <Table.HeadCell></Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        1
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <span className="fi fi-lk"></span> Sri Lanka
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={100}
                          className="bg-blue-700 text-white"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={"30.5%"}
                          className="bg-red-500 text-white"
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        2
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <span className="fi fi-ca fi"></span> Canada
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={80}
                          className="bg-blue-700 text-white"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={"20.5%"}
                          className="bg-red-500 text-white"
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        3
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <span className="fi fi-in fi"></span> India
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={45}
                          className="bg-blue-700 text-white"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={"25.5%"}
                          className="bg-red-500 text-white"
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        4
                      </Table.Cell>
                      <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                        <span className="fi fi-au fi"></span> Australia
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={23}
                          className="bg-blue-700 text-white"
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <TwBadge
                          title={"17%"}
                          className="bg-red-500 text-white"
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

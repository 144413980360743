/**
 * Provides API endpoint paths for short URLs, campaigns, and tags.
 *
 * @example
 * // Get short URL by campaign key and query parameters
 * api.short_url.get('campaign123', 'status=active');
 *
 * // Create a new short URL
 * api.short_url.create;
 *
 * // Delete a short URL by ID
 * api.short_url.delete(123);
 *
 * // Update a short URL by ID
 * api.short_url.update(123);
 *
 * // Get campaigns with optional query parameters
 * api.campaign.get('status=active');
 *
 * // Create a new campaign
 * api.campaign.create;
 *
 * // Delete a campaign by key
 * api.campaign.delete('campaign123');
 *
 * // Update a campaign by key
 * api.campaign.update('campaign123');
 *
 * // Get tags with optional query parameters
 * api.tags.get('type=featured');
 */
export const api = {
  short_url: {
    get: (campaignKey: string, querParams: string) =>
      `/shorturl/${campaignKey}?${querParams}`,
    create: "/shorturl/create",
    delete: (shortUrlId: number) => `/shorturl/delete/${shortUrlId}`,
    update: (shortUrlId: number) => `/shorturl/update/${shortUrlId}`,
  },
  campaign: {
    get: (querParams: string) =>
      querParams ? `/campaign?${querParams}` : "/campaign",
    create: "/campaign/create",
    delete: (campaignKey: string) => `/campaign/delete/${campaignKey}`,
    update: (campaignKey: string) => `/campaign/update/${campaignKey}`,
  },
  tags: {
    get: (querParams: string) => (querParams ? `/tags?${querParams}` : "/tags"),
  },
};

// React and external library imports
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
// Application-specific imports
import { Skeleton } from "./Skeleton";
import { IAuthRouteProps } from "../../common/interfaces/ICommon";

/**
 * A component that wraps child components and ensures that they are only
 * accessible to authenticated users. If the user is not authenticated,
 * they are redirected to the homepage. If authentication status is loading,
 * a loading skeleton is displayed.
 *
 * @param {AuthRouteProps} props - The props for the component.
 * @param {JSX.Element} props.children - The child elements to be conditionally rendered.
 *
 * @returns {JSX.Element} - Returns a loading skeleton, a redirect, or the child elements
 *   based on the authentication status.
 */
const AuthRoute: React.FC<IAuthRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  if (isLoading) {
    return <Skeleton />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default AuthRoute;

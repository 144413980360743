// React and ReactDOM imports
import React from "react";
import ReactDOM from "react-dom/client";

// External libraries
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Auth0Provider } from "@auth0/auth0-react";

// Application-specific imports
import "./index.css";
import App from "./App";
import envConfig from "./config/envConfig";

/**
 * Initialize a new instance of QueryClient for React Query.
 * @type {QueryClient}
 */
const queryClient = new QueryClient();

/**
 * Render the root React component and wrap it with necessary providers.
 *
 * This is the entry point of the React application. It sets up the root element and applies
 * context providers for React Query and Auth0 authentication.
 *
 * @function
 */
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    {/* Provide the QueryClient instance to the React Query context */}
    <QueryClientProvider client={queryClient}>
      {/* Set up Auth0 provider for authentication */}
      <Auth0Provider
        domain={envConfig.auth0Domain}
        clientId={envConfig.auth0ClientId}
        authorizationParams={{
          redirect_uri: envConfig.auth0RedirectUri,
        }}
      >
        {/* Main application component */}
        <App />
      </Auth0Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

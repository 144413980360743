// React and external library imports
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet } from "react-router-dom";
// resources
import { Header } from "./header/Header";
import { LeftNavBar } from "./navigation/LeftNavBar";
import { BreadcrumbNav } from "../shared/Breadcrumb";

/**
 * Layout component serves as the main layout for the application, incorporating a sidebar, header, breadcrumb navigation, and toast notifications.
 *
 * @returns {JSX.Element} The rendered Layout component.
 */
export const Layout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  /**
   * Toggles the sidebar's open state.
   */
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="container">
      <div className="flex flex-row w-screen h-screen m-0">
        <LeftNavBar
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
        />
        <div className="flex-auto bg-customColor right-column">
          <Header onMenuClick={toggleSidebar} />
          <div className="h-[calc(100vh-56px)] overflow-auto bg-slate-50 max-w-none">
            <BreadcrumbNav />
            <Toaster toastOptions={{ duration: 3500 }} />
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

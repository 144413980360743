// React and external library imports
import React from "react";

/**
 * A functional component that renders a skeleton loader.
 * This component is used as a placeholder while content is loading.
 * It displays a grid of rectangular and square placeholder blocks
 * with a shadow and rounded corners to mimic the shape of the content
 * that will eventually be loaded.
 *
 * @returns {JSX.Element} - Returns a JSX element representing the skeleton loader.
 */
export const Skeleton: React.FC = () => (
  <div className="max-w-7xl mx-auto p-5">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="p-2 bg-white shadow rounded-lg">
        <div className="w-full h-80 skeleton rounded-lg"></div>
      </div>
      <div className="p-2 bg-white shadow rounded-lg">
        <div className="w-full h-80 skeleton rounded-lg"></div>
      </div>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-5">
      <div className="p-2 bg-white shadow rounded-lg">
        <div className="w-full h-10 skeleton rounded-lg"></div>
      </div>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-5">
      <div className="p-2 bg-white shadow rounded-lg">
        <div className="w-full h-80 skeleton rounded-lg"></div>
      </div>
      <div className="p-2 bg-white shadow rounded-lg">
        <div className="w-full h-80 skeleton rounded-lg"></div>
      </div>
    </div>
  </div>
);

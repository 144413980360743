// React and external library imports
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
// Application-specific imports
import { ITagsProps } from "../../../common/interfaces/IForm";
import {
  tagMaxCreatable,
  tagMaxLength,
  tagMinLength,
} from "../../../common/constant/common";

const animatedComponents = makeAnimated();

/**
 * `TwTags` component allows users to select and create tags with multi-select support.
 *
 * @param {ITagsProps} props - Component properties.
 * @param {Array<any>} props.defaultTags - Initial selected tags.
 * @param {Array<{ value: string, label: string }>} props.options - Options for the dropdown.
 * @param {(tags: any) => void} props.onChange - Callback for when tags change.
 *
 * @returns {JSX.Element} The rendered `TwTags` component.
 *
 * @example
 * <TwTags
 *   defaultTags={[{ value: 'tag1', label: 'Tag 1' }]}
 *   options={[{ value: 'tag1', label: 'Tag 1' }, { value: 'tag2', label: 'Tag 2' }]}
 *   onChange={(tags) => console.log('Tags:', tags)}
 * />
 */
export const TwTags: React.FC<ITagsProps> = ({
  defaultTags,
  options,
  onChange,
}) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (newValue: string) => {
    const value =
      newValue.length > tagMaxLength
        ? newValue.slice(0, tagMaxLength)
        : newValue;
    setInputValue(value);
    return value;
  };

  const handleOnChange = (selectedTags: any) => {
    setTags(selectedTags);
    onChange(selectedTags);
  };

  const handleIsValidNewOption = (inputValue: string) =>
    inputValue.length >= tagMinLength && tags.length < tagMaxCreatable;

  return (
    <>
      <CreatableSelect
        defaultValue={defaultTags}
        placeholder=""
        isMulti
        isClearable
        components={animatedComponents}
        options={options}
        onChange={handleOnChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        isValidNewOption={handleIsValidNewOption}
      />
      <em className="text-sm text-gray-600 mt-1">
        {t("twTags.helpText", {
          tagMinLength,
          tagMaxLength,
          tagMaxCreatable,
        })}
      </em>
    </>
  );
};

import moment from "moment";
const isoFormat = "YYYY-MM-DD";

/**
 * Checks if an error object has a valid response.
 * @param error - The error object to check.
 * @returns {boolean} - Whether the error object is valid.
 */
export const checkApiError = (error: any) => {
  return (
    error &&
    typeof error === "object" &&
    "response" in error &&
    typeof error.response === "object"
  );
};

/**
 * Formats a date to a specified format or default format.
 * @param date - The date to format.
 * @param format - Optional format string.
 * @returns {string} - The formatted date.
 */
export const formatDate = (date: Date, format = "") => {
  if (!format) {
    format = "MMMM DD, YYYY";
  }
  return moment(date).format(format);
};

/**
 * Returns a human-readable string representing the time from a given date.
 * @param date - The date to compare to the current time.
 * @returns {string} - Time from now.
 */
export const fromNow = (date: Date) => {
  return moment(formatDate(date, isoFormat)).fromNow();
};

/**
 * Converts an object to URL query parameters.
 * @param obj - The object to convert.
 * @returns {string} - The query string.
 */
export const objToQueryParams = (obj: any) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`${key}[]`, item.toString());
        });
      } else {
        params.append(key, value.toString());
      }
    }
  }
  return params.toString();
};

/**
 * Converts an array of objects to a format suitable for React Select.
 * @param tags - The array of tags to convert.
 * @returns {Array<{ label: string; value: string }>} - The formatted array.
 */
export const objToReactSelect = (tags: any | undefined = []) => {
  return tags.map((tag: any) => ({
    label: tag.value,
    value: tag.value,
  }));
};

/**
 * Converts an array of React Select objects to an array of strings.
 * @param tags - The array of React Select tags.
 * @returns {string[]} - The array of string values.
 */
export const reactSelectobjToArray = (tags: any | undefined = []) => {
  return tags.map((tag: any) => tag.value.toString());
};

/**
 * Converts an array of React Select objects to a concatenated string.
 * @param tags - The array of React Select tags.
 * @returns {string} - The concatenated string.
 */
export const reactSelectobjToString = (tags: any | undefined = []) => {
  return tags.map((tag: any) => tag.value).join("@@");
};

/**
 * Truncate a string to a specified length and append an ellipsis if it exceeds that length.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} maxLength - The maximum length of the text before truncation.
 * @returns {string} - The truncated text with an ellipsis if needed.
 */
export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + "...";
};

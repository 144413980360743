/**
 * Constants for pagination, tags, and tag types.
 *
 * @constant {number} paginationPerpage - Number of items per page in pagination.
 * @constant {number} tagMaxLength - Maximum length of a tag (in characters).
 * @constant {number} tagMaxShow - Maximum number of tags to show at once.
 * @constant {number} tagMaxCreatable - Maximum number of creatable tags allowed.
 * @constant {number} tagMinLength - Minimum length required for a tag (in characters).
 * @constant {Object} tagType - Types of tags.
 * @property {number} tagType.campaign - Identifier for campaign tags.
 * @property {number} tagType.shorturl - Identifier for short URL tags.
 */
export const paginationPerpage = 10;
export const tagMaxLength = 20;
export const tagMaxShow = 4;
export const tagMaxCreatable = 15;
export const tagMinLength = 3;
export const tagType = {
  campaign: 1,
  shorturl: 2,
};

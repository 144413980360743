// React and external library imports
import React from "react";
// Application-specific imports
import { ITwButtonProps } from "../../../common/interfaces/IForm";

/**
 * TwButton component renders a customizable button with various color themes.
 *
 * This component allows you to create buttons with different colors, styles, and states (e.g., disabled).
 * It supports passing a click handler and custom CSS class names for additional styling.
 *
 * @param {ITwButtonProps} props - The properties for the TwButton component.
 * @param {string} props.title - The text to display on the button.
 * @param {'button' | 'submit' | 'reset'} [props.type='button'] - The type of the button element. Defaults to "button".
 * @param {() => void} [props.onClick] - Optional click handler function to be called when the button is clicked.
 * @param {string} [props.className=''] - Optional additional CSS class names to apply to the button.
 * @param {'blue' | 'zinc' | 'gray' | 'light'} [props.color='blue'] - Color theme for the button. Defaults to "blue".
 * @param {boolean} [props.disabled=false] - Whether the button is disabled. Defaults to false.
 *
 * @returns {JSX.Element} The rendered TwButton component.
 *
 * @example
 * <TwButton
 *   title="Click Me"
 *   type="submit"
 *   onClick={() => console.log('Button clicked')}
 *   color="blue"
 *   className="extra-class"
 * />
 */
export const TwButton: React.FC<ITwButtonProps> = ({
  title,
  type = "button",
  onClick,
  className = "",
  color = "blue",
  disabled = false,
}) => {
  const theme: Record<string, string> = {
    blue: "bg-blue-500 hover:bg-blue-600 focus:bg-blue-800 active:bg-blue-700",
    zinc: "bg-zinc-500 hover:bg-zinc-600 focus:bg-zinc-800 active:bg-zinc-700",
    gray: "bg-gray-500 hover:bg-gray-600 focus:bg-gray-800 active:bg-gray-700",
    light:
      "ring-cyan-700 hover:ring-cyan-800 focus:ring-cyan-900 active:ring-cyan-700 text-black",
  };

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`${theme[color]} 
          inline-block rounded text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] 
          focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] 
          px-6 pb-2.5 pt-2.5 text-sm font-medium leading-normal transition duration-150 ease-in-out focus:outline-none focus:ring-0 ${className}`}
    >
      {title}
    </button>
  );
};

// React and external library imports
import React from "react";
//Icons
import { IoCalendarOutline } from "react-icons/io5";
// Application-specific imports
import { TwBadge } from "./TwBadge";
import { IInlineDate } from "../../../common/interfaces/IForm";

/**
 * TwInlineDate component displays a date label with a calendar icon inside a badge.
 *
 * This component utilizes the `TwBadge` component to present a date label with an associated calendar icon.
 * The badge is styled with a gray background and is intended to be used for inline date displays.
 *
 * @param {IInlineDate} props - The properties for the TwInlineDate component.
 * @param {string} props.title - The text to display within the badge.
 *
 * @returns {JSX.Element} The rendered TwInlineDate component.
 *
 * @example
 * <TwInlineDate title="2024-07-30" />
 */
export const TwInlineDate: React.FC<IInlineDate> = ({ title }) => (
  <div className="flex-shrink-0 flex">
    <TwBadge title={title} icon={IoCalendarOutline} className="bg-gray-200" />
  </div>
);

// React and external library imports
import React from "react";
import { useTranslation } from "react-i18next";
// Application-specific imports
import { ICampaign } from "../../common/interfaces/IForm";
import { useCampaignMutateQuery } from "../../queries/useCampaignQuery";
import CampaignForm from "./CampaignForm";

/**
 * CampaignCreate component handles the creation of a new campaign.
 *
 * @component
 * @example
 * return <CampaignCreate />;
 *
 * This component uses a form for creating a new campaign and handles
 * submission using the `createCampaign` function from the `useCampaignMutateQuery`
 * hook. It also manages loading, success, and error states, and provides
 * localization using `react-i18next`.
 *
 * @returns {JSX.Element} The rendered CampaignCreate component.
 */
const CampaignCreate: React.FC = () => {
  const { t } = useTranslation();
  const {
    isSuccess,
    isCreating,
    isApiError,
    apiError,
    isGeneralError,
    apiSuccessMessage,
    createCampaign,
  }: any = useCampaignMutateQuery();

  /**
   * Handles form submission by invoking the createCampaign function.
   *
   * @param {ICampaign} data - The campaign data submitted through the form.
   */
  const onSubmit = (data: ICampaign): any => {
    createCampaign(data);
  };

  return (
    <div className="max-w-4xl p-5 mx-auto my-10 bg-white rounded-md shadow-sm">
      <CampaignForm
        onSubmit={onSubmit}
        isApiError={isApiError}
        isPending={isCreating}
        apiError={apiError}
        isSuccess={isSuccess}
        isGeneralError={isGeneralError}
        apiSuccessMessage={apiSuccessMessage}
        formTitle={t("campaignCreate.formTitle")}
        submitButton={t("campaignCreate.button.create")}
      />
    </div>
  );
};

export default CampaignCreate;

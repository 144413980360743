// Application-specific imports
import Dashboard from "../../components/dashboard/Dashboard";
import ShortUrlCreate from "../../components/shorturl/ShortUrlCreate";
import Campaign from "../../components/campaign/Campaigns";
import ShortUrls from "../../components/shorturl/ShortUrls";
import CampaignCreate from "../../components/campaign/CampaignCreate";
import CampaignEdit from "../../components/campaign/CampaignEdit";
import ShortUrlEdit from "../../components/shorturl/ShortUrlEdit";

/**
 * Application routes configuration.
 *
 * Defines paths and components for home, link management, and campaign management.
 *
 * @constant {Object} route - Route paths and dynamic builders.
 * @constant {Array<Object>} appRoutes - Array mapping paths to components.
 * @property {string} appRoutes.path - The route path.
 * @property {React.ElementType} appRoutes.element - Component for the route.
 * @property {boolean} [appRoutes.index] - Indicates if it's the index route.
 */
export const route = {
  home: "/",
  dashboard: "/dashboard",
  logout: "/logout",
  link: {
    lists: "/links",
    create: `/:id/link/create`,
    edit: `/:campaign_key/link/edit/:id`,
    createId: (campaignKey: string) => `/${campaignKey}/link/create`,
    editId: (campaignKey: string, shorturlId: number) =>
      `/${campaignKey}/link/edit/${shorturlId}`,
  },
  campaign: {
    lists: "/campaigns",
    create: "/campaign/create",
    edit: "/campaign/edit/:id",
    details: "/campaign/:id",
    selectId: (campaignKey: string) => `/campaign/${campaignKey}`,
    editId: (campaignKey: string) => `/campaign/edit/${campaignKey}`,
  },
};

export const appRoutes = [
  { path: route.dashboard, element: Dashboard, index: true },
  { path: route.link.create, element: ShortUrlCreate },
  { path: route.link.edit, element: ShortUrlEdit },
  { path: route.campaign.lists, element: Campaign },
  { path: route.campaign.create, element: CampaignCreate },
  { path: route.campaign.edit, element: CampaignEdit },
  { path: route.campaign.details, element: ShortUrls },
];

// React and external library imports
import React, { useEffect } from "react";
import { Badge, Tooltip } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
// Icons
import { IoPricetagsOutline } from "react-icons/io5";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { HiPresentationChartLine } from "react-icons/hi";
import { IoIosLink } from "react-icons/io";
// Application-specific imports
import { route } from "../../common/constant/route";
import { tagMaxShow } from "../../common/constant/common";
import { formatDate } from "../../common/helpers/helper";
import { useCampaignMutateQuery } from "../../queries/useCampaignQuery";
import { TwBadge, TwTitle, TwInlineDate, TwActionItems } from "../shared/tw";

/**
 * CampaignCard component displays information about a campaign.
 *
 * @component
 * @example
 * const campaign = {
 *   title: "Summer Sale",
 *   description: "Huge discounts on summer items!",
 *   campaignKey: "summer-sale",
 *   createdAt: "2024-07-30T12:00:00Z",
 *   urlCount: 5,
 *   tags: [{ value: "Sale" }, { value: "Discount" }]
 * };
 *
 * return <CampaignCard {...campaign} />;
 *
 * @param {Object} props - Component props.
 * @param {string} props.title - Title of the campaign.
 * @param {string} [props.description] - Description of the campaign.
 * @param {string} props.campaignKey - Unique identifier for the campaign.
 * @param {string} [props.createdAt] - Date when the campaign was created.
 * @param {number} [props.urlCount] - Number of URLs associated with the campaign.
 * @param {Array<Object>} [props.tags] - List of tags associated with the campaign.
 * @returns {JSX.Element} The rendered CampaignCard component.
 */

const CampaignCard: React.FC<any> = ({
  title,
  description,
  campaignKey,
  createdAt,
  urlCount,
  tags,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDeleted, deleteCampaign, apiSuccessMessage } =
    useCampaignMutateQuery();

  /**
   * Handles navigation to the selected campaign.
   */
  const handleVisitCampaign = () => {
    navigate(route.campaign.selectId(campaignKey));
  };

  /**
   * Handles the deletion of the campaign.
   */
  const handleDeleteCampaign = () => {
    const options: any = {
      title: t("campaignCard.alert.title"),
      text: t("campaignCard.alert.text"),
      icon: t("campaignCard.alert.icon"),
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("campaignCard.alert.confirmButtonText"),
    };

    Swal.fire(options).then((result) => {
      if (result.isConfirmed) {
        deleteCampaign(campaignKey);
      }
    });
  };

  /**
   * Handles navigation to the campaign edit page.
   */
  const handleEditCampaign = () => {
    navigate(route.campaign.editId(campaignKey));
  };

  const actions = [
    {
      icon: MdModeEdit,
      title: t("actions.edit"),
      onclick: handleEditCampaign,
    },
    {
      icon: MdDelete,
      title: t("actions.delete"),
      onclick: handleDeleteCampaign,
    },
    {
      icon: HiPresentationChartLine,
      title: t("actions.viewShortUrl"),
      onclick: handleVisitCampaign,
    },
  ];

  useEffect(() => {
    if (isDeleted) {
      toast.success(apiSuccessMessage);
    }
  }, [isDeleted, apiSuccessMessage]);

  return (
    <div className="col-span-1 md:col-span-2" key={campaignKey}>
      <div className="bg-white shadow-md rounded-lg px-7 py-5">
        <div className="flex justify-between items-center mb-2 capitalize">
          <span className="hover:cursor-pointer" onClick={handleVisitCampaign}>
            <TwTitle title={title} />
          </span>
          <TwActionItems actions={actions} />
        </div>
        {description && (
          <p
            onClick={handleVisitCampaign}
            className="text-gray-700 mb-2 border-b border-blue-400 pb-3 hover:cursor-pointer"
          >
            {description}
          </p>
        )}
        <div className="flex flex-col md:flex-row gap-2 items-start md:items-center">
          <div className="inline-flex">
            {createdAt && (
              <TwInlineDate
                title={t("campaignCard.badge.createdDate", {
                  date: formatDate(createdAt),
                })}
              />
            )}
          </div>
          <div className="flex-grow flex items-center flex-wrap overflow-hidden">
            <TwBadge
              onClick={handleVisitCampaign}
              className="bg-gray-200 flex-shrink-0 hover:cursor-pointer"
              icon={IoIosLink}
              title={t("campaignCard.badge.urlCount", { count: urlCount })}
            />
            {tags?.length > 0 && (
              <>
                <IoPricetagsOutline className="ml-2 mr-2 h-5 w-5 flex-shrink-0" />
                {tags.slice(0, tagMaxShow).map((tag: any, index: number) => (
                  <TwBadge
                    key={index}
                    className="mr-2 mb-1 text-white flex-shrink-0 bg-blue-500 pb-1"
                    title={tag.value}
                  />
                ))}
                {tags.length > tagMaxShow && (
                  <Tooltip
                    placement="right"
                    content={
                      <div className="flex flex-col">
                        {tags
                          .slice(tagMaxShow)
                          .map((tag: any, index: number) => (
                            <p
                              key={index}
                              className="mb-2 text-gray-200"
                              color="gray"
                            >
                              {tag.value}
                            </p>
                          ))}
                      </div>
                    }
                  >
                    <Badge
                      className="mr-2 mb-1 text-gray-600 flex-shrink-0"
                      color="gray"
                    >
                      {t("campaignCard.badge.more", {
                        count: tags.length - tagMaxShow,
                      })}
                    </Badge>
                  </Tooltip>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
